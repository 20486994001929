import apiClient from "@/api/apiClient";

// initial state
const state = {
    config: null
}

// getters
const getters = {

}

// actions
const actions = {
    getConfig({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.config !== null) {
            return state.config;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/app-config')
            .then(response => {
                commit('SET_CONFIG', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_CONFIG(state, config) {
        state.config = config;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
