<template>
    <div v-html="html"></div>
</template>

<script>
export default {
    name: "TikTokEmbed",
    props: {
        url: {
            type: String,
        }
    },
    data() {
        return {
            html: ''
        };
    },
    mounted() {
        if (window.tiktokEmbed) {
            this.fetchEmbed();
        } else {
            this.injectScript();
            this.checkAPI().then(() => {
                this.fetchEmbed();
            })
        }
    },
    methods: {
        fetchEmbed() {
            this.validateUrl();
            let escapedUrl = encodeURIComponent(this.url);
            const url = `https://www.tiktok.com/oembed?url=${escapedUrl}`;
            fetch(url)
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then(data => {
                    var div = document.createElement('div');
                    div.innerHTML = data.html;
                    var scripts = div.getElementsByTagName('script');
                    var i = scripts.length;
                    while (i--) {
                        scripts[i].parentNode.removeChild(scripts[i]);
                    }
                    this.html = div.innerHTML;

                    this.$nextTick(() => {
                        window.tiktokEmbed.lib.init
                    });
                })
                .catch(e => {
                    throw new Error(e);
                })
        },
        validateUrl() {
            // const urlRe = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            // if (!urlRe.test(this.url)) {
            //     throw new Error(`${this.url} - Invalid url`);
            // }
        },
        /**
         * inject instagram embed script
         */
        injectScript() {
            const protocolToUse = 'https:';
            const s = document.createElement('script');
            s.async = s.defer = true;
            s.src = `${protocolToUse}//www.tiktok.com/embed.js`;
            s.id = 'vue-tiktok-embed-script';
            const body = document.body;
            if (body) {
                body.appendChild(s);
            }
        },
        /**
         * Check for window.instgrm
         */
        checkAPI() {
            return new Promise(resolve => {
                (function checkAPI(self) {
                    self.timer = window.setTimeout(() => {
                        if (window.tiktokEmbed) {
                            clearTimeout(self.timer);
                            resolve();
                        } else {
                            checkAPI(self);
                        }
                    }, 20);
                })(this);
            });
        }
    }
}
</script>

<style scoped>

</style>
