<template>
    <div>
        <v-btn
            :to="{name: 'Home'}"
            exact
            :depressed="$vuetify.breakpoint.lgAndUp"
            :plain="$vuetify.breakpoint.lgAndUp"
            :block="$vuetify.breakpoint.lgAndUp"
        >
            <span>Home</span>
            <v-icon
                class="mb-1"
            >
                jv-icon jv-icon-home
            </v-icon>
        </v-btn>
        <v-btn
            :to="{name: 'Challenges'}"
            :depressed="$vuetify.breakpoint.lgAndUp"
            :plain="$vuetify.breakpoint.lgAndUp"
            :block="$vuetify.breakpoint.lgAndUp"
        >
            <span>Challenges</span>
            <v-icon
                class="mb-1"
            >
                jv-icon jv-icon-flag
            </v-icon>
        </v-btn>
        <v-btn
            :to="{name: 'Submissions'}"
            :depressed="$vuetify.breakpoint.lgAndUp"
            :plain="$vuetify.breakpoint.lgAndUp"
            :block="$vuetify.breakpoint.lgAndUp"
        >
            <span>Inzendingen</span>
            <v-icon
                class="mb-1"
            >
                jv-icon jv-icon-star
            </v-icon>
        </v-btn>
        <v-btn
            :to="{name: 'Information'}"
            :depressed="$vuetify.breakpoint.lgAndUp"
            :plain="$vuetify.breakpoint.lgAndUp"
            :block="$vuetify.breakpoint.lgAndUp"
        >
            <span>Meedoen</span>
            <v-icon
                class="mb-1"
            >
                jv-icon jv-icon-plus
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "NavigationButtons"
}
</script>

<style scoped>

</style>
