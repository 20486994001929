<template>
    <v-container class="spelshow-landing">
        <div class="spelshow-container">
            <div class="spelshow-logo">
                <v-img src="@/assets/images/spelshow-logo.png" :max-width="234" :max-height="124"></v-img>
            </div>

            <div v-if="isSubmitted" class="text-center">
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-6 mt-1">Upload <strong>gelukt!</strong></h2>
                <p>Bedankt voor je inzending</p>
            </div>
            <div v-else>
                <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
                <div v-if="config && config['spelshow.doeopdracht']" v-html="config['spelshow.doeopdracht']"></div>

                <v-form
                    ref="form"
                    v-model="isValid"
                    lazy-validation
                    @submit="submit"
                >
                    <v-text-field
                        v-model="entry.teamName"
                        :rules="teamNameRules"
                        :disabled="isSubmitting"
                        placeholder="Teamnaam"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.contactName"
                        :rules="contactNameRules"
                        :disabled="isSubmitting"
                        placeholder="Contactpersoon (voor- en achternaam)"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.email"
                        :rules="emailRules"
                        :disabled="isSubmitting"
                        placeholder="E-mailadres"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.phoneNumber"
                        :rules="phoneNumberRules"
                        :disabled="isSubmitting"
                        placeholder="Telefoonnummer"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>

                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showImageWarning}"
                    >
                        <label>Foto</label>
                        <v-file-input v-model="image"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text white"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <p class="error--text mb-5 mt-2" v-if="showImageWarning">Verplicht</p>

                    <v-btn
                        class="black--text"
                        color="error"
                        :disabled="isSubmitting"
                        block
                        depressed
                        rounded
                        @click="submit"
                    >
                        Inzenden
                    </v-btn>
                </v-form>
            </div>
        </div>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import apiClient from "@/api/apiClient";

export default {
    name: "SpelshowDoeOpdracht",
    data: () => ({
        isSubmitted: false,
        isSubmitting: false,
        isValid: false,
        entry: {
            teamName: null,
            contactName: null,
            email: null,
            phoneNumber: null,
            imageFile: null,
        },
        image: null,
        teamNameRules: [v => !!v || 'Verplicht'],
        contactNameRules: [v => !!v || 'Verplicht'],
        emailRules: [v => !!v || 'Verplicht'],
        phoneNumberRules: [v => !!v || 'Verplicht'],
        imageRules: [v => !!v || 'Verplicht'],
    }),
    computed: {
        showImageWarning() {
            return !this.image
        },
        ...mapState({
            config: state => state.config.config
        })
    },
    methods: {
        submit() {
            if (this.$refs.form.validate() && !this.showImageWarning && !this.showFileWarning) {
                this.doSubmit();
            }
        },
        doSubmit() {

            this.isSubmitting = true;
            this.convertToBase64(this.image).then(base64 => {
                this.entry.imageFile = base64

                return apiClient.post('/spelshow/signup', {entry: this.entry});
            }).then(response => {
                this.isSubmitted = response.data.success;
                window.scrollTo({top: 0, behavior: 'smooth'});
            }).finally(() => {
                this.isSubmitting = false;
            })
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
    }
}
</script>

<style scoped>

</style>
