<template>
    <v-container v-if="challenge">
        <router-link :to="{ 'name': 'Challenges' }" class="back-link mb-3">
            <span class="mr-1">&lt;</span> Alle challenges
        </router-link>

        <h1 class="text-h1 mb-3"><strong>{{ challenge.name }}</strong> Challenge</h1>

        <VimeoVideo
            :video-id="challenge.videoId"
            class="mx-n3 mx-md-0 mb-5 rounded-video"
            v-if="challenge.videoId"
        ></VimeoVideo>
<!--        TODO: image-->
        <v-img
            v-if="!challenge.videoId && challenge.extraImage1"
            :src="challenge.extraImage1.url"
            height="75"
            cover
            class="rounded-lg"
        >
        </v-img>

        <div class="d-flex justify-end align-center flex-wrap mt-3">
<!--            TODO: challenge category link -->
            <v-btn
                class="black--text px-5"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                v-if="false"
            >
                Alle kookchallenges
            </v-btn>

            <v-btn
                class="black--text px-5 mb-2"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                v-if="hasSpelregelsInDescription"
                @click="$vuetify.goTo('#spelregels')"
            >
                Spelregels
            </v-btn>
            <NewsButton :challenge="challenge" class="mb-2 ml-3"></NewsButton>
            <v-btn
                class="black--text px-5 mb-2 ml-3"
                v-if="isLoaded && challenge.isActive && firstActiveSubchallenge && challenge.slug === 'nieuweblikken' && this.firstActiveSubchallenge.slug === 'stap-1'"
                :to="{name:'Subchallenge', params: { challengeSlug: this.challenge.slug, subchallengeSlug: this.firstActiveSubchallenge.slug }}"
                color="primary"
                depressed
                rounded
            >
                Aanmelden vooruitblik-avonden
            </v-btn>

            <v-btn
                v-if="challenge.slug === 'deleukstesocial'"
                class="black--text px-5 ml-2 ml-md-3 mb-2"
                color="primary"
                depressed
                rounded
                :to="{name: 'ChallengeSubmissions', params: { challengeSlug: challenge.slug}}"
            >
                Like jouw favo social post!
            </v-btn>

            <v-btn
                v-if="challenge.isActive && challenge.slug === 'deuitblinkers' && firstActiveSubchallenge"
                class="black--text px-5 mb-2 ml-3"
                color="primary"
                depressed
                rounded
                :to="{name: 'EntryForm', params: { challengeSlug: challenge.slug, subchallengeSlug: firstActiveSubchallenge.slug }}"
            >
                Insturen
            </v-btn>

            <p
                class="status mt-4 mb-0 mb-2 ml-3"
                v-if="!challenge.isActive"
            >
                <v-icon
                    color="warning"
                    class="mr-1"
                >
                    fa fa-circle
                </v-icon>
                {{ challenge.subtitleCta }}
            </p>
        </div>
        <v-divider class="mb-5 mt-3"></v-divider>

        <v-row v-if="isLoaded">
            <v-col v-if="challenge.introduction">
                <p v-html="challenge.introduction"></p>
            </v-col>
        </v-row>

        <SubchallengeSlider v-if="isLoaded" :challenge="challenge" :subchallenges="defaultChallenges"></SubchallengeSlider>

        <div v-if="getSubchallengesByCategory('online').length > 0">
            <h2 class="text-h1 mb-4"><strong>Online</strong> challenges</h2>
            <SubchallengeSlider v-if="isLoaded" :challenge="challenge" :subchallenges="getSubchallengesByCategory('online')"></SubchallengeSlider>
        </div>
        <div v-if="getSubchallengesByCategory('fysiek').length > 0">
            <h2 class="text-h1 mb-4"><strong>Fysieke</strong> challenges</h2>
            <SubchallengeSlider v-if="isLoaded" :challenge="challenge" :subchallenges="getSubchallengesByCategory('fysiek')"></SubchallengeSlider>
        </div>
        <div v-if="getSubchallengesByCategory('meestervoorspeller').length > 0">
            <h2 class="text-h1 mb-4"><strong>Meester</strong>voorspeller</h2>
            <p>2021 wordt een fantastisch sportjaar! We verheugen ons op het moois dat de toppers in de sportwereld gaan laten zien. Van de renners van Team Jumbo-Visma tot Max Verstappen. En natuurlijk niet te vergeten, de evenementen zoals het EK voetbal en de Tour de France.</p>
            <p>Denk jij die topvoorspeller te zijn van de uitkomsten van de grote sportwedstrijden en -evenementen van 2021? Doe dan mee met Scorito. Deze spellen zijn gratis voor Jumbo collega’s, eenvoudig mee te spelen, en maken de wedstrijden nóg leuker en spannender.</p>
            <p>Wil jij de titel Meestervoorspeller van Jumbo dragen en een tof prijzenpakket in de wacht slepen? Ga de uitdaging aan, speel mee via Scorito en versla je collega’s!</p>
            <SubchallengeSlider v-if="isLoaded" :challenge="challenge" :subchallenges="getSubchallengesByCategory('meestervoorspeller')"></SubchallengeSlider>
        </div>

        <div class="d-flex justify-end align-center pt-4" v-if="!challenge.isActive">
<!--            TODO: download challenge link -->
            <v-btn
                class="black--text px-3"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                v-if="false"
            >
                Download challenge
                <v-icon
                    small
                    class="ml-2"
                >
                    jv-icon jv-icon-file
                </v-icon>
            </v-btn>
            <v-btn
                class="black--text px-5 ml-3"
                :to="{name:'Subchallenge', params: { challengeSlug: this.challenge.slug, subchallengeSlug: this.firstActiveSubchallenge.slug }}"
                color="primary"
                depressed
                rounded
                v-if="isLoaded && challenge.isActive && firstActiveSubchallenge && challenge.slug === 'nieuweblikken' && this.firstActiveSubchallenge.slug === 'stap-1'"
            >
                <!-- TODO: Configureer maken inschrijflink -->
                Aanmelden vooruitblik-avonden
            </v-btn>
            <p
                class="status mb-0 ml-3"
                v-if="!challenge.isActive"
            >
                <v-icon
                    color="warning"
                    class="mr-1"
                >
                    fa fa-circle
                </v-icon>
                {{ challenge.subtitleCta }}
            </p>
        </div>

        <v-divider class="mb-5 mt-5"></v-divider>

        <ChallengeCoaches v-if="showChallengeCoaches" :challenge="challenge"></ChallengeCoaches>
        <v-divider v-if="showChallengeCoaches" class="mb-5 mt-5"></v-divider>

        <div v-if="false">
<!--        TODO: top 5 submissions -->
        <h2 class="text-h1 text-script text-center mb-8 mt-8">Zij gingen je voor...</h2>
        <h3 class="text-h2 heading-border">
            <strong>De top 5</strong> inzendingen
        </h3>

        <p class="mt-1 mb-0 text-center">
            <v-btn
                class="black--text px-5 mx-auto"
                color="grey lighten-1"
                depressed
                rounded
                outlined
            >
                Bekijk alle inzendingen
            </v-btn>
        </p>
        </div>

        <News ref="news" :challenge="challenge"></News>

        <div v-if="challenge.description" class="mt-5">
            <div v-html="challenge.description"></div>
        </div>

        <v-divider class="mb-5 mt-5" v-if="challenge.slug === 'deuitblinkers'"></v-divider>

        <h2 class="text-h1 mb-4" v-if="challenge.slug === 'deuitblinkers'"><strong>Inzendingen</strong></h2>
        <BattleEntryGrid v-if="challenge.slug === 'deuitblinkers'" :challenge="challenge"></BattleEntryGrid>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";
import VimeoVideo from "../components/VimeoVideo";
import ChallengeCoaches from "../components/ChallengeCoaches";
import News from "../components/News";
import NewsButton from "../components/NewsButton";
import SubchallengeSlider from "../components/SubchallengeSlider";
import BattleEntryGrid from "../components/BattleEntryGrid";

export default {
    name: "Challenge",
    components: {
        BattleEntryGrid,
        SubchallengeSlider,
        NewsButton,
        News,
        ChallengeCoaches,
        BottomContent,
        VimeoVideo
    },
    mounted() {
        this.loadChallenge(this.$route.params.slug);
    },
    data: () => ({
        challenge: null,
        isLoaded: false
    }),
    computed: {
        firstActiveSubchallenge () {
            if (!this.isLoaded) {
                return null;
            }
            return this.challenge.subchallenges.find(s => s.isActive);
        },
        showChallengeCoaches () {
            return this.challenge && this.challenge.slug === 'nieuweblikken';
        },
        hasSpelregelsInDescription () {
            return this.challenge && this.challenge.description && this.challenge.description.indexOf('id="spelregels"') !== -1;
        },
        defaultChallenges() {
            if (this.challenge.slug === 'deuitblinkers') {
                return [];
            }
            return this.getSubchallengesByCategory('default')
                .filter(subchallenge => {
                    return (this.challenge && this.challenge.slug !== 'deuitblinkers') || (subchallenge.displayOrder > 0);
                });
        }
    },
    methods: {
        loadChallenge(slug) {
            // Eerste de snelle versie in de cache opzoeken
            this.challenge = this.$store.state.challenges.challenges.find(c => c.slug === slug);

            // Daarna de rest zoeken
            this.$store.dispatch('getChallengeDetail', {slug}).then((challenge) => {
                this.challenge = challenge
                this.isLoaded = true;
            })
        },
        getSubchallengesByCategory(category) {
            if (!this.challenge.subchallenges) {
                return [];
            }
            return this.challenge.subchallenges.filter(sc => sc.category === category);
        }
    }
}
</script>

<style scoped>

</style>
