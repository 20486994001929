<template>
    <v-btn
        v-if="isVisible"
        class="black--text px-5"
        color="grey lighten-1"
        depressed
        rounded
        outlined
        @click="scrollToNews"
    >
        Nieuws & Tips
        <v-icon
            color="error"
            class="ml-1 ml-md-2 circle"
            v-if="isRedDotVisible"
        >
            fa fa-circle
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "NewsButton",
    props: {
        challenge: {
            required: true,
            type: Object
        },
        subchallenge: {
            required: false,
            type: Object
        },
    },
    computed: {
        newsPosts () {
            if (this.subchallenge) {
                return this.$store.state.news.news.filter(n => n.subChallenges.includes(this.subchallenge.slug));
            } else {
                return this.$store.state.news.news.filter(n => n.challenges.includes(this.challenge.slug));
            }
        },
        isVisible () {
            return this.newsPosts.length > 0;
        },
        isRedDotVisible () {
            // TODO: Nog logica bepalen wanneer rode stip weg mag
            return true;
        }
    },
    methods: {
        scrollToNews () {
            this.$vuetify.goTo('#news')
        }
    }
}
</script>

<style scoped>

</style>
