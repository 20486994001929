<template>
    <v-container v-if="challenge">
        <router-link
            :to="(subchallenge) ? {name:'Subchallenge', params: {slug: subchallenge.slug}} : {name:'Challenge', params: {slug: challenge.slug}}"
            class="back-link mb-3"
        >
            <span class="mr-1">&lt;</span>
            <span v-if="subchallenge">{{ subchallenge.name }}</span>
            <span v-else-if="challenge">{{ challenge.name }}</span>
        </router-link>

        <h1 class="text-h1 mb-0"><strong>{{ challenge.name }}</strong> Challenge</h1>
        <h2 class="text-h2 text--secondary mb-3" v-if="subchallenge">{{ subchallenge.name }}</h2>

        <v-img
            v-if="isLoaded && !isSubmitted"
            :src="headerImage"
            height="75"
            cover
            class="rounded-lg mb-5"
        >
        </v-img>

        <p class="status mb-0 ml-3" v-if="!isActive">
            <v-icon
                color="warning"
                class="mr-1"
            >
                fa fa-circle
            </v-icon>
            Deze challenge is nog niet actief. Kom later terug!
        </p>

        <v-row v-if="isLoaded && isActive && !isSubmitted">
            <v-col>
                <p class="display-3 text-script text-center mb-2">Je bent er bijna</p>
                <div v-if="challenge.slug === 'delekkerste'">
                    <v-divider class="mb-5 mt-5"></v-divider>
                    <p>Voor de eerste ronde van de najaarseditie van De Lekkerste Challenge stuur je de naam en een foto van
jouw gerecht in. Ook hebben we een aantal gegevens van je nodig.</p>
                </div>

                <v-divider class="mb-5 mt-5"></v-divider>
                <h2 class="text-h2 mb-4">Jouw <strong>gegevens</strong></h2>
                <v-form
                    ref="form"
                    v-model="isValid"
                    lazy-validation
                    @submit="submit"
                >
                    <div class="custom-upload-field mt-3 mb-5"
                         v-if="(entryType === 'videoupload' && challenge.slug !== 'deuitblinkers')"
                    >
                        <label>Upload video</label>
                        <v-file-input
                            :disabled="isSubmitting"
                            accept="video/mp4"
                            v-model="video"
                        ></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <v-radio-group
                        v-if="isTouwtjeSpringen"
                        v-model="entry.subType"
                        row
                        hide-details="auto"
                        class="mb-5"
                    >
                        <v-radio
                            label="Snelste 100"
                            value="snelste"
                        ></v-radio>
                        <v-radio
                            label="Leukste 100"
                            value="leukste"
                        ></v-radio>
                    </v-radio-group>
                    <v-text-field
                        v-if="isTouwtjeSpringen && entry.subType === 'snelste'"
                        v-model="entry.score"
                        :rules="touwtjeSpringenTimeRules"
                        :disabled="isSubmitting"
                        placeholder="Tijd (in seconden)"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="entryType === 'social'"
                        v-model="entry.linkUrl"
                        :rules="linkUrlRules"
                        :disabled="isSubmitting"
                        placeholder="Link naar de post op Facebook of Instagram"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.description"
                        :rules="descriptionRules"
                        :disabled="isSubmitting"
                        v-if="challenge.slug !== 'deuitblinkers'"
                        :placeholder="challenge.slug === 'delekkerste' ? 'Naam gerecht' : 'Beschrijving'"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.lastName"
                        :rules="lastNameRules"
                        :disabled="isSubmitting"
                        placeholder="Jouw naam (voor- en achternaam)"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.email"
                        :rules="emailRules"
                        :disabled="isSubmitting"
                        placeholder="E-mail"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="challenge.slug === 'delekkerste'"
                        v-model="entry.phoneNumber"
                        :rules="phoneNumberRules"
                        :disabled="isSubmitting"
                        placeholder="Telefoonnummer"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="false"
                        v-model="entry.age"
                        :rules="ageRules"
                        :disabled="isSubmitting"
                        placeholder="Leeftijd"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-select
                        :items="['Jumbo Hoofdkantoor', 'La Place hoofdkantoor', 'La Place restaurant', 'Jumbo winkel', 'Site', 'Hub', 'EFC']"
                        label="Werklocatie"
                        v-model="entry.workLocation"
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    >
                    </v-select>
                    <v-text-field
                        v-model="entry.shopName"
                        :rules="shopNameRules"
                        :disabled="isSubmitting"
                        placeholder="Naam werklocatie/afdeling"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.description"
                        :rules="descriptionRules"
                        :disabled="isSubmitting"
                        v-if="challenge.slug === 'deuitblinkers'"
                        placeholder="Titel talent"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="challenge.slug === 'deuitblinkers'"
                        v-model="entry.remarks"
                        :rules="remarksRules"
                        :disabled="isSubmitting"
                        placeholder="Korte beschrijving"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="false"
                        v-model="entry.managerName"
                        :rules="managerNameRules"
                        :disabled="isSubmitting"
                        placeholder="Naam leidinggevende"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="false"
                        v-model="entry.managerPhoneNumber"
                        :rules="managerPhoneNumberRules"
                        :disabled="isSubmitting"
                        placeholder="Telefoonnummer leidinggevende"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="false"
                        v-model="entry.managerEmail"
                        :rules="managerEmailRules"
                        :disabled="isSubmitting"
                        placeholder="E-mailadres leidinggevende"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-if="isRemarksVisible"
                        v-model="entry.remarks"
                        :rules="remarksRules"
                        :disabled="isSubmitting"
                        placeholder="Namen betrokken collega's"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <div class="custom-upload-field mt-3 mb-5"
                         v-if="(entryType === 'videoupload' && challenge.slug === 'deuitblinkers') || (entryType === 'photovideoupload') || (entryType === 'uploadcluster')"
                    >
                        <label>Upload video</label>
                        <v-file-input
                            :disabled="isSubmitting"
                            accept="video/mp4"
                            v-model="video"
                        ></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showImageWarning}"
                         v-if="challenge.slug === 'deuitblinkers' || (entryType === 'photovideoupload') || (entryType === 'uploadcluster')"
                    >
                        <label>Foto/afbeelding</label>
                        <v-file-input v-model="image"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>

                    <v-select
                        :items="['Geen cluster', 'B_01 Koen Vermeulen', 'J_01 Ward de Bruyn', 'J_02 Nicole Wolters', 'J_03 Wouter Augusteijn', 'J_04 Rene van Schijndel', 'J_06 Hans Wagner', 'J_07 Josja van Popta', 'J_10 Peter Versantvoort', 'J_21 Joost de Bont', 'J_23 Jurriaan Peters', 'J_32 Robert Konkelaar', 'J_50 Jan Kroek', 'J_52 Rene Beijersbergen', 'J_53 Rob Hol', 'J_75 Fenno Munneke', 'J_80 Mick Ouweneel', 'J_84 Ton Kappen', 'J_88 Ron Pul', 'J_89 Erik Bolwerk', 'J_90 Peter Gorter', 'J_93 Marion van Dijk']"
                        v-if="(entryType === 'uploadcluster')"
                        label="Cluster"
                        v-model="entry.cluster"
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-select>

                    <v-text-field
                        v-model="entry.surprise"
                        :rules="surpriseRules"
                        :disabled="isSubmitting"
                        placeholder="Jumbo verrast! Vertel eens, wat is voor jou de ultieme verrassing?"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>

                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showImageWarning}"
                         v-if="entryType === 'social'"
                    >
                        <label>Screenshot toevoegen van post</label>
                        <v-file-input v-model="image"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <p class="error--text mb-5 mt-2" v-if="showImageWarning && entryType === 'social'">Verplicht</p>

                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showFileWarning}"
                         v-if="challenge.slug !== 'delekkerste' && entryType === 'documentupload'"
                    >
                        <label>Je recept (download hiervoor het receptformulier bovenaan de pagina)</label>
                        <v-file-input v-model="file"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <p class="error--text mb-5 mt-2" v-if="showFileWarning && challenge.slug === 'delekkerstedisabled'">Verplicht</p>

                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showImageWarning}"
                         v-if="challenge.slug === 'delekkerste'"
                    >
                        <label>Foto van je gerecht</label>
                        <v-file-input v-model="image"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <p class="error--text mb-5 mt-2" v-if="showImageWarning && challenge.slug === 'delekkerste'">Verplicht</p>

                    <v-checkbox
                        v-if="challenge.slug === 'delekkerste'"
                        label="Ik geef akkoord op de spelregels en toestemming om de ingevulde gegevens op te slaan voor deze challenge. Deze gegevens worden uitsluitend voor deze challenge gebruikt en worden na afloop verwijderd."
                        required
                    >
                    </v-checkbox>

                    <v-progress-linear
                      color="light-green"
                      height="25"
                      class="rounded-lg my-5"
                      striped
                      :value="videoUploadProgress"
                      v-if="videoUploadProgress !== null"
                    >
                      <template v-slot:default="{ value }">
                        <strong>Bezig met uploaden: {{ Math.ceil(value) }}%</strong>
                      </template>
                    </v-progress-linear>

                    <v-btn
                        v-if="isLoaded"
                        class="black--text"
                        color="primary"
                        :disabled="isSubmitting"
                        block
                        depressed
                        rounded
                        @click="submit"
                    >
                        Inschrijving voltooien
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>

        <v-row v-if="isLoaded && isActive && isSubmitted">
            <v-col class="text-center" v-if="subchallenge.entryType === 'social'">
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-6 mt-1">Upload <strong>gelukt!</strong></h2>
                <v-btn
                    class="black--text"
                    color="primary"
                    block
                    depressed
                    rounded
                    :to="{name:'Entry', params: {challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug, entryToken: this.submittedEntry.token}}"
                >
                    Bekijk hier je inzending
                </v-btn>
            </v-col>
            <v-col class="text-center" v-else-if="challenge.slug === 'deuitblinkers'">
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-6 mt-1">Inzending <strong>verstuurd!</strong></h2>
                <p>We hebben een e-mail verstuurd.</p>
                <v-alert
                    prominent
                    :icon="false"
                    type="success"
                    class="jumbo-alert"
                >
                    Check je e-mail
                </v-alert>
            </v-col>
            <v-col class="text-center" v-else-if="subchallenge.entryType === 'videoupload' || subchallenge.entryType === 'photovideoupload'">
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-6 mt-1">Upload <strong>gelukt!</strong></h2>
                <p>Bedankt voor je inzending. We tonen hem snel in het overzicht.</p>
            </v-col>
            <v-col class="text-center" v-else-if="challenge.slug === 'delekkerste'">
                <VimeoVideo :video-id="522812180"></VimeoVideo>
                <h2 class="text-h1 mb-5 mt-1">Upload <strong>gelukt!</strong></h2>
                <p>Bedankt voor je inzending. We tonen hem snel in het overzicht.</p>
            </v-col>
            <v-col class="text-center" v-else>
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-5 mt-1">Upload <strong>gelukt!</strong></h2>
                <p>Bedankt voor je inzending. We tonen hem snel in het overzicht.</p>
            </v-col>
        </v-row>
        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";

import Uppy from '@uppy/core'
const Tus = require('@uppy/tus')
import VimeoVideo from "../components/VimeoVideo";
export default {
    name: "EntryForm",
    components: {BottomContent, VimeoVideo},
    mounted() {
        this.loadSubchallenge(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug);

        this.uppy = new Uppy().use(Tus, {
            resume: true,
            retryDelays: [0, 1000, 3000, 5000],
        });
        this.uppy.on('progress', (progress) => {
            this.videoUploadProgress = progress;
        })
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false,
        isSubmitted: false,
        isSubmitting: false,
        isValid: false,
        uppy: null,
        videoUploadProgress: null,
        entry: {
            firstName: null,
            lastName: null,
            employeeNo: null,
            email: null,
            linkUrl: null,
            description: null,
            shopName: null,
            remarks: null,
            phoneNumber: null,
            age: null,
            workLocation: null,
            managerName: null,
            managerPhoneNumber: null,
            managerEmail: null,
            subType: null,
            score: null,
            surprise: null
        },
        touwtjeSpringen: {
            type: 'snelste',
            time: null
        },
        lastNameRules: [
            v => !!v || 'Naam is verplicht'
        ],
        emailRules: [
            v => !!v || 'E-mail is verplicht',
            v => /.+@.+\..+/.test(v) || 'Ongeldig e-mailadres',
        ],
        linkUrlRules: [
            v => !!v || 'Link is verplicht',
            v => {

                if (v) {
                    let validPrefixes = [
                        'https://www.instagram.com/p/',
                        'https://vm.tiktok.com/',
                        'https://www.tiktok.com/@',
                        'https://fb.watch/',
                        'https://www.facebook.com/'
                    ]
                    for (var i = 0; i < validPrefixes.length; i++) {
                        if (v.startsWith(validPrefixes[i])) {
                            return true;
                        }
                    }
                }
                return 'Ongeldig type link'
            }
            // TODO: Meer rules
        ],
        descriptionRules: [
            v => !!v || 'Beschrijving is verplicht'
        ],
        shopNameRules: [
            v => !!v || 'Winkelnaam is verplicht'
        ],
        remarksRules: [
            v => !!v || 'Veld is verplicht'
        ],
        touwtjeSpringenTimeRules: [
            v => !!v || 'Veld is verplicht'
        ],
        phoneNumberRules: [
            v => !!v || 'Veld is verplicht'
        ],
        ageRules: [
            v => !!v || 'Veld is verplicht'
        ],
        managerNameRules: [
            v => !!v || 'Veld is verplicht'
        ],
        managerPhoneNumberRules: [
            v => !!v || 'Veld is verplicht'
        ],
        managerEmailRules: [
            v => !!v || 'Veld is verplicht'
        ],
        surpriseRules: [
            v => !!v || 'Veld is verplicht'
        ],
        image: null,
        video: null,
        file: null,
        submittedEntry: null
    }),
    computed: {
        headerImage () {
            if (this.subchallenge.extraImage1) {
                return this.subchallenge.extraImage1.url;
            } else {
                return this.challenge.extraImage2.url;
            }
        },
        isActive () {
            return (!this.challenge || this.challenge.isActive) && (!this.subchallenge || this.subchallenge.isActive);
        },
        entryType() {
            return this.subchallenge.entryType;
        },
        isRemarksVisible () {
            return this.challenge && this.subchallenge && this.challenge.slug === 'deleukstesocial';
        },
        isTouwtjeSpringen() {
            return this.challenge && this.subchallenge && this.subchallenge.slug === 'touwtjespringen';
        },
        showImageWarning () {
            if (this.challenge && this.challenge.slug === 'delekkerste') {
                return !this.image;
            } else if (this.entry.linkUrl && this.entry.linkUrl.startsWith('https://www.facebook.com/')) {
                return !this.image;
            } else {
                return false;
            }
        },
        showFileWarning() {
            // if (this.challenge && this.challenge.slug === 'delekkerste') {
            //     return !this.file;
            // } else {
                return false;
            // }
        }
    },
    watch: {
        'entry.subType' () {
            this.updateTouwtjeSpringenRemarks()
        },
        'entry.score' () {
            this.updateTouwtjeSpringenRemarks()
        }
    },
    methods: {
        updateTouwtjeSpringenRemarks() {
            if (this.entry.subType === 'snelste') {
                this.entry.remarks = 'Snelste 100' + (this.entry.score ? ' - '+ this.entry.score + ' seconden' : '');
            } else {
                this.entry.remarks = 'Leukste 100';
            }
        },
        loadSubchallenge(challengeSlug, subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            this.$store.dispatch('getSubchallengeDetail', {challengeSlug, subchallengeSlug}).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;

                // redirect terug naar detailpagina als inschrijving is uitgeschakeld:
                if (this.subchallenge.entryType === 'none') {
                    this.$router.push({name: 'Subchallenge', params: { challengeSlug: subchallenge.challenge.slug, subchallengeSlug: subchallenge.slug }});
                }
                this.isLoaded = true;
            })
        },
        submit () {
            if (this.$refs.form.validate() && !this.showImageWarning && !this.showFileWarning) {
                this.doSubmit();
            }
        },
        doSubmit() {
            const storeSubmit = () =>  this.$store.dispatch('submitEntry', { subchallenge: this.subchallenge, entry: this.entry })
                .then(result => {
                    this.isSubmitted = result.success;
                    this.submittedEntry = result.entry;

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }).finally(() =>{
                    this.isSubmitting = false;
                })

            this.isSubmitting = true;

            let promises = [];
            if (this.video) {
                promises.push(this.uploadVideo(this.video)
                    .then(result => {
                        this.entry.linkUrl = result.link;
                    }))
            }

            if (this.image) {
                promises.push(this.convertToBase64(this.image).then(base64 => {
                    this.entry.imageFile = base64
                }))
            }

            if (this.file) {
                promises.push(this.convertToBase64(this.file).then(base64 => {
                    this.entry.attachedFile = base64
                }))
            }

            return Promise.all(promises).then(() => {
                storeSubmit();
            })
        },
        convertToBase64 (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        uploadVideo (file) {
            console.log('%cvideo upload%c retrieving upload url for ', 'color: #B10DC9', 'color: #33333', file);
            return this.$store.dispatch('getVideoUploadUrl', {
                challengeSlug: this.subchallenge.challenge.slug,
                subchallengeSlug: this.subchallenge.slug,
                size: file.size,
                description: this.entry.description
            })
                .then(uploadTicket => {
                    console.log('%cvideo upload%c got url ' + uploadTicket.upload_url, 'color: #B10DC9', 'color: #33333');

                    let tusPlugin = this.uppy.getPlugin('Tus');
                    tusPlugin.opts.endpoint = uploadTicket.upload_url;
                    tusPlugin.opts.uploadUrl = uploadTicket.upload_url;

                    try {
                        this.uppy.addFile({
                            source: 'file input',
                            name: file.name,
                            type: file.type,
                            data: file
                        })
                        console.log('%cvideo upload%c added file to uppy', 'color: #B10DC9', 'color: #33333');
                    } catch (err) {
                        if (err.isRestriction) {
                            // handle restrictions
                            console.log('Restriction error:', err)
                        } else {
                            // handle other errors
                            console.error(err)
                        }
                        throw err;
                    }

                    console.log('%cvideo upload%c starting upload', 'color: #B10DC9', 'color: #33333');
                    return this.uppy.upload().then(result => {
                        console.log('%cvideo upload%c upload success', 'color: #B10DC9', 'color: #33333', result);
                        return uploadTicket
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
