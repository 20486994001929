<template>
    <v-container class="login-container align-start pl-0 pr-0" fill-height>
        <div class="login-content px-3">
            <h1 class="text-h1 text-script text-center pt-4 pb-5">Welkom!</h1>
            <h2 class="text-h2 mb-5"><strong>Log hier in</strong> en doe snel mee!</h2>
            <v-form
                ref="form"
                v-model="isValid"
                class="pt-5"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-alert type="error" v-if="isInvalidPassword">Ongeldig wachtwoord</v-alert>
                <span class="label">Wachtwoord</span>
                <div class="password-field">
                    <v-text-field
                        ref="passwordField"
                        :type="(showPasswordCharacters) ? 'text' : 'password'"
                        v-model="password"
                        :rules="passwordRules"
                        class="mt-1 mb-3"
                        dense
                        hide-details
                        outlined
                        required
                        rounded
                    ></v-text-field>
                    <v-icon
                        small
                        @click.stop.prevent="showPasswordCharacters = !showPasswordCharacters"
                    >
                        fas {{ passwordIcon }}
                    </v-icon>
                </div>
                <v-btn
                    block
                    class="black--text"
                    color="primary"
                    depressed
                    rounded
                    type="submit"
                >
                    Inloggen
                </v-btn>
            </v-form>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Login",
    data: () => ({
        showPasswordCharacters: false,
        isValid: true,
        isInvalidPassword: false,
        password: '',
        passwordRules: [
            v => !!v || 'Geen wachtwoord ingevuld'
        ]
    }),
    computed: {
        passwordIcon() {
            return (this.showPasswordCharacters) ? 'fa-eye-slash' : 'fa-eye'
        }
    },
    watch: {
        password () {
            this.isInvalidPassword = false;
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('login', {
                    password: this.password
                }).then(success => {
                    if (success) {
                        this.$router.push({name: 'Home'})
                    } else {
                        this.isInvalidPassword = true;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldig wachtwoord'});
                    }
                }).catch((e) => {
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
