<template>
    <div class="mb-4">
        <div v-for="(subchallenge, index) in visibleSubchallenges" :key="subchallenge.slug">
            <h2 class="small-title mb-0">{{ subchallenge.name }}</h2>
            <p class="mt-0">{{subchallenge.subtitle}}</p>

            <swiper
                :options="swiperOptions"
                class="battle-card-slider"
            >
                <swiper-slide>
                    <div class="battle-card">
                        <router-link
                            :to="{name: 'EntryForm', params: { challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug}}"
                            class="rounded-lg cta"
                        >
                            <v-img
                                contain
                                height="50"
                                src="../assets/images/dat-kan-ik-beter.svg"
                            ></v-img>
                            <v-icon>
                                fa fa-plus
                            </v-icon>
                        </router-link>
                    </div>
                </swiper-slide>
                <swiper-slide
                    v-for="(entry, index) in getEntries(subchallenge)"
                    :key="entry.token"
                >
                    <div class="battle-card">
                        <router-link
                            :to="{name:'BattleEntry', params: {challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug, entryToken: entry.token}}"
                            :style="{ 'background-image' : entry.image ? 'url(' + entry.image.url + ')' : null }"
                            class="rounded-lg"
                        >
                            <span class="rank" v-if="showRanking && entry.likeCount"><span>{{ getRank(subchallenge, entry, index) }}</span></span>
                        </router-link>
                    </div>
                </swiper-slide>
            </swiper>

            <v-divider class="mb-4 mt-4" v-if="index < visibleSubchallenges.length - 1"></v-divider>

        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: "BattleEntryGrid",
    props: {
        challenge: {
            type: Object,
        },
        showRanking: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    mounted() {
        this.loadEntries();
    },
    data: () => ({
        entries: {},
        swiperOptions: {
            slidesPerView: 'auto',
            autoplay: false
        }
    }),
    computed: {
        visibleSubchallenges() {
            if (!this.challenge || !this.challenge.subchallenges) {
                return [];
            }
            return this.challenge.subchallenges.filter(s => s.displayOrder > 0);
        }
    },
    watch: {
        challenge() {
            this.loadEntries();
        }
    },
    methods: {
        loadEntries() {
            this.visibleSubchallenges.forEach(subchallenge => {
                this.$store.dispatch('getSubchallengeEntries', {
                    challengeSlug: this.challenge.slug,
                    subchallengeSlug: subchallenge.slug
                }).then(entries => {
                    this.entries[subchallenge.slug+''] = entries
                    this.$forceUpdate();
                })
            });

        },
        getEntries(subchallenge) {
            let entries = this.entries[subchallenge.slug];

            if (this.showRanking && entries) {
                entries = entries.sort((a, b) => {
                    let va = a.likeCount;
                    let vb = b.likeCount;
                    return (vb - va);
                })
            }

            return entries;
        },
        getRank(subchallenge, entry, index) {
            if (!this.showRanking) {
                return null;
            }

            const rankingMode = subchallenge.rankingMode;
            switch (rankingMode) {
                case 'top10':
                case 'top3':
                    return entry.rank;
                case 'votes':
                    return entry.voteCount > 0 ? (index + 1) : null;
                case 'score':
                    return entry.score > 0 ? (index + 1) : null;
                case 'likes':
                    return entry.likeCount > 0 ? (index + 1) : null;
                default:
                    return null;
            }
        }

    }
}
</script>

<style scoped>

</style>
