<template>
    <v-container>
        <h1 class="text-h1" :class="{ 'mb-8': challenges.length < 1, 'mb-6': challenges.length >= 1 }"><strong>Alle
            toppers</strong> op een rij</h1>

        <div v-if="challenges.length >= 1">
            <v-btn-toggle dense class="submissions-toggle mb-7" mandatory>
                <v-btn value="nieuwste" :to="getRouteForView('nieuwste')"
                       :class="{'v-btn--active': activeView === 'nieuwste'}">
                    Nieuwste
                </v-btn>
                <v-btn value="klassement" :to="getRouteForView('klassement')"
                       :class="{'v-btn--active': activeView === 'klassement'}">
                    Klassement
                </v-btn>
            </v-btn-toggle>

            <v-slide-group class="tile-category-slider">

                <v-slide-item
                    v-for="challenge in visibleChallenges"
                    :key="challenge.slug"
                >
                    <router-link :class="{ 'active': challenge.slug === activeChallengeSlug }"
                                 :to="getRouteForChallenge(challenge)" class="category">
                        {{ challenge.name }}
                    </router-link>
                </v-slide-item>
            </v-slide-group>

            <div
                v-if="visibleSubchallenges && visibleSubchallenges.length > 1 && activeChallengeSlug !== 'deuitblinkers'"
                class="mt-4 mb-2">
                <v-btn v-for="subchallenge in visibleSubchallenges"
                       :key="subchallenge.slug"
                       color="primary"
                       :outlined="subchallengeSlug !== subchallenge.slug"
                       rounded
                       depressed
                       class="black--text px-2 mr-2 mb-2"
                       @click.prevent="subchallengeSlug = subchallenge.slug"
                       small
                >
                    {{ subchallenge.name }}
                </v-btn>
            </div>

            <v-divider class="mt-3 mb-5"></v-divider>
        </div>

        <BattleEntryGrid
            v-if="showBattleGrid"
            :challenge="activeChallenge"
            :show-ranking=" activeView === 'klassement'"
        ></BattleEntryGrid>

        <div v-else>
            <EntryTile
                v-for="(entry, index) in sortedEntries"
                :key="'submission-' + entry.token"
                :class="{ 'last': (index === (entries.length - 1)) }"
                :entry="entry"
                :rank="getRank(entry, index)"
                :counter-mode="counterMode"
            ></EntryTile>
        </div>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";
import EntryTile from "../components/EntryTile";
import BattleEntryGrid from "../components/BattleEntryGrid";

export default {
    name: "Submissions",
    components: {BattleEntryGrid, EntryTile, BottomContent},
    mounted() {
        this.loadEntryConfig().then(() => {
            if (this.activeView === 'klassement') {
                this.subchallengeSlug = this.visibleSubchallenges[0].slug;
            } else {
                this.subchallengeSlug = null;
            }
            this.loadEntries()
        })
    },
    data: () => ({
        isLoading: true,
        entries: [],
        challenges: [],
        subchallengeSlug: null
    }),
    watch: {
        $route() {
            if (this.activeView === 'klassement') {
                this.subchallengeSlug = this.visibleSubchallenges[0].slug;
            } else {
                this.subchallengeSlug = null;
            }
            this.loadEntries();
        }
    },
    computed: {
        activeChallengeSlug() {
            if (this.$route.params.challengeSlug) {
                return this.$route.params.challengeSlug
            } else {
                return 'alle';
            }
        },
        activeView: {
            get() {
                return this.$route.params.view || 'nieuwste'
            },
            set(newView) {
                console.log(newView)
            }
        },
        counterMode() {
            if (this.activeSubchallenge && this.activeSubchallenge.rankingMode === 'likes') {
                return 'likes';
            }
            return 'votes';
        },
        showBattleGrid() {
            return this.activeChallengeSlug === 'deuitblinkers';
        },
        visibleChallenges() {
            let challenges = [];

            if (this.activeView === 'nieuwste') {
                challenges.push({
                    name: 'Alles',
                    slug: 'alle'
                })
            }

            challenges = [...challenges, ...this.challenges.filter(c => {
                if (this.activeView === 'nieuwste') {
                    return true;
                } else {
                    return c.hasRanking
                }
            })]

            return challenges;
        },
        visibleSubchallenges() {
            if ((this.challenges.length === 0) || (!this.activeChallengeSlug) || (this.activeChallengeSlug === 'alle')) {
                return [];
            }

            let activeChallenge = this.activeChallenge;
            if (!activeChallenge) {
                return [];
            }
            let subchallenges = activeChallenge.subchallenges || [];

            if (this.activeChallengeSlug === 'deuitblinkers') {
                subchallenges = subchallenges.filter(s => s.displayOrder > 0);
            }

            return subchallenges;
        },
        activeChallenge() {
            return this.challenges.find(c => c.slug === this.activeChallengeSlug);
        },
        sortedEntries() {
            let entries = this.entries;
            if (!entries) {
                return [];
            }

            if (this.subchallengeSlug) {
                entries = entries.filter(e => {
                    if (this.subchallengeSlug.startsWith('touwtjespringen')) {
                        const subType = this.subchallengeSlug.substring(16);
                        return e.subchallenge.slug === 'touwtjespringen' && e.subType === subType;
                    } else {
                        return e.subchallenge.slug === this.subchallengeSlug
                    }
                })
            }

            if (this.activeView === 'klassement') {
                const activeSubchallenge = this.activeSubchallenge;
                if (!activeSubchallenge) {
                    return [];
                }

                const rankingMode = this.activeSubchallenge.rankingMode;
                let sortField = null;
                let sortDirection = 1;

                switch (rankingMode) {
                    case 'votes':
                        sortField = 'voteCount';
                        entries = entries.filter(e => {
                            return e.isVotingEnabled;
                        })
                        break;
                    case 'score':
                        sortField = 'score';
                        sortDirection = -1;
                        break;
                    case 'top10':
                    case 'top3':
                        sortField = 'rank';
                        sortDirection = -1;
                        var maxRank = (rankingMode === 'top10') ? 10 : 3;
                        entries = entries.filter(e => {
                            return (e.rank !== null) && (e.rank <= maxRank);
                        })
                        break;
                    case 'likes':
                    default:
                        sortField = 'likeCount';
                        break;
                }

                entries = entries.sort((a, b) => {
                    let va = a[sortField];
                    let vb = b[sortField];
                    return (vb - va) * sortDirection;
                })
            }

            return entries;
        },
        activeSubchallenge() {
            if (!this.visibleSubchallenges) {
                return null;
            }
            if (this.visibleSubchallenges.length === 1) {
                return this.visibleSubchallenges[0];
            }
            return this.visibleSubchallenges.find(s => s.slug === this.subchallengeSlug);
        }
    },
    methods: {
        getRouteForView(view) {
            if (view === 'nieuwste') {
                return {name: 'ChallengeSubmissions', params: {view: view, challengeSlug: 'alle'}}
            } else {
                return {
                    name: 'ChallengeSubmissions',
                    params: {view: view, challengeSlug: this.challenges.find(c => c.hasRanking).slug}
                }
            }
        },
        getRouteForChallenge(challenge) {
            return {name: 'ChallengeSubmissions', params: {view: this.activeView, challengeSlug: challenge.slug}};
        },
        loadEntryConfig() {
            this.isLoading = true;
            return this.$store.dispatch('getEntryConfig').then(config => {
                this.challenges = config;
            });
        },
        loadEntries() {
            this.isLoading = true;
            this.entries = [];
            if (this.activeChallengeSlug === 'alle') {
                this.$store.dispatch('getAllEntries').then(entries => {
                    this.entries = entries
                    this.isLoading = false;
                })
            } else {
                this.$store.dispatch('getChallengeEntries', {challengeSlug: this.activeChallengeSlug}).then(entries => {
                    this.entries = entries
                    this.isLoading = false;
                })
            }
        },
        getRank(entry, index) {
            if (this.activeView !== 'klassement') {
                return null;
            }

            const rankingMode = this.activeSubchallenge.rankingMode;
            switch (rankingMode) {
                case 'top10':
                case 'top3':
                    return entry.rank;
                case 'votes':
                    return entry.voteCount > 0 ? (index + 1) : null;
                case 'score':
                    return entry.score > 0 ? (index + 1) : null;
                case 'likes':
                    return entry.likeCount > 0 ? (index + 1) : null;
                default:
                    return null;
            }
        }
    }
}
</script>

<style scoped>

</style>
