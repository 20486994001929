<template>
    <v-container class="spelshow-landing">
        <div class="spelshow-container">
            <div class="spelshow-logo">
                <v-img src="@/assets/images/spelshow-logo.png" :max-width="234" :max-height="124"></v-img>
            </div>
            <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
            <div v-if="config" v-html="config['spelshow.programma']"></div>
        </div>
    </v-container>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "SpelshowVoorwaarden",
    computed: {
        ...mapState({
            config: state => state.config.config
        })
    },
}
</script>

<style scoped>

</style>
