<template>
    <v-container>

        <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
        <div v-if="config" v-html="config['prizes.text']" class="mb-7"></div>

        <BottomContent></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../../components/BottomContent";
import {mapState} from "vuex";

export default {
    name: "Rules",
    components: {BottomContent},
    computed:{
        ...mapState({
            config: state => state.config.config
        })
    }
}
</script>

<style scoped>

</style>
