<template>
    <div v-html="html" class="d-flex justify-center rounded-lg"></div>
</template>

<script>
export default {
    name: "FacebookVideo",
    props: {
        url: {
            type: String,
        },
        maxWidth: {
            type: Number,
            default: 320
        },
        hideCaption: {
            type: Boolean,
            default: false
        },
        omitScript: {
            type: Boolean,
            default: true
        },
        accessToken: {
            type: String,
        }
    },
    data() {
        return {
            html: ''
        };
    },
    mounted() {
        if (window.FB) {
            this.fetchEmbed();
        } else {
            this.injectScript();
            this.checkAPI().then(() => {
                this.fetchEmbed();
            })
        }
    },
    methods: {
        fetchEmbed() {
            this.validateUrl();
            const maxWidth = this.maxWidth >= 320 ? this.maxWidth : 320
            const url = `https://graph.facebook.com/v10.0/oembed_video?url=${this.url}&maxwidth=${maxWidth}&hidecaption=${this.hideCaption}&omitscript=${this.omitScript}&access_token=${this.accessToken}`;
            fetch(url)
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                })
                .then(data => {
                    this.html = data.html;
                    this.$nextTick(() => {
                        window.FB.XFBML.parse();
                    });
                })
                .catch(e => {
                    throw new Error(e);
                })
        },
        validateUrl() {
            const urlRe = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            if (!urlRe.test(this.url)) {
                throw new Error(`${this.url} - Invalid url`);
            }
        },
        /**
         * inject instagram embed script
         */
        injectScript() {
            const protocolToUse = 'https:';
            const s = document.createElement('script');
            s.async = s.defer = true;
            s.src = `${protocolToUse}//connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v10.0`;
            s.id = 'vue-facebook-embed-script';
            const body = document.body;
            if (body) {
                body.appendChild(s);
            }
        },
        /**
         * Check for window.instgrm
         */
        checkAPI() {
            return new Promise(resolve => {
                (function checkAPI(self) {
                    self.timer = window.setTimeout(() => {
                        if (window.FB) {
                            clearTimeout(self.timer);
                            resolve();
                        } else {
                            checkAPI(self);
                        }
                    }, 20);
                })(this);
            });
        }
    }
}
</script>

<style scoped>

</style>
