<template>
    <v-container v-if="subchallenge">
        <div v-if="!isSubmitted">
            <router-link
                v-if="subchallenge && entry"
                :to="{name: 'Entry', params: {
                            challengeSlug: challenge.slug,
                            subchallengeSlug: subchallenge.slug,
                            entryToken: entry.token
                        }}"
                class="back-link mb-3">
                <span class="mr-1">&lt;</span> Terug
            </router-link>

            <h1 class="text-h1 mb-3"><strong>Jouw keuze</strong></h1>

            <EntryTile
                v-if="entry"
                :entry="entry"
                :challenge="challenge"
                :subchallenge="subchallenge"
            ></EntryTile>

            <v-divider class="my-5"></v-divider>

            <v-alert color="error" v-if="errorMessage">
                {{errorMessage}}
            </v-alert>

            <v-form
                ref="form"
                v-model="isValid"
                lazy-validation
                @submit="submit"
            >
                <v-text-field
                    v-model="vote.name"
                    :rules="nameRules"
                    :disabled="isSubmitting"
                    placeholder="Jouw naam (voor- en achternaam)"
                    required
                    outlined
                    rounded
                    dense
                    hide-details
                    class="my-3"
                ></v-text-field>
                <v-text-field
                    v-model="vote.email"
                    :rules="emailRules"
                    :disabled="isSubmitting"
                    placeholder="E-mail"
                    required
                    outlined
                    rounded
                    dense
                    hide-details
                    class="my-3"
                ></v-text-field>

                <v-btn
                    v-if="isLoaded"
                    class="black--text"
                    color="primary"
                    :disabled="isSubmitting"
                    block
                    depressed
                    rounded
                    @click="submit"
                >
                    Stemmen
                </v-btn>
            </v-form>
        </div>
        <div v-else><!-- Submitted -->
            <v-row>
                <v-col>
                    <h2 class="text-h1 mb-5 mt-1 text-center">Je bent er <strong>bijna....</strong></h2>
                    <v-img
                        src="https://media.giphy.com/media/ZZr97FVxIMiPGPT9s6/giphy.gif"
                        aspect-ratio="1"
                        class="mb-6 rounded-lg mx-auto"
                        max-width="300px"
                    >
                    </v-img>
                    <p class="text-center">Je bent er bijna, alleen nog je stem bevestigen.</p>
                    <p class="text-center">We hebben je een e-mail gestuurd naar het adres: <strong>{{vote.email}}</strong></p>
                    <v-alert
                      prominent
                      :icon="false"
                      type="success"
                      class="jumbo-alert"
                    >
                        Check je e-mail
                    </v-alert>
                </v-col>
            </v-row>
        </div>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";
import EntryTile from "../components/EntryTile";

export default {
    name: "EntryForm",
    components: {
        EntryTile,
        BottomContent
    },
    mounted() {
        this.loadSubchallenge(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug)
            .then(() => {
                this.loadEntry(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            })
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false,
        entry: null,
        isSubmitted: false,
        isSubmitting: false,
        isValid: false,
        errorMessage: null,
        vote: {
            name: '',
            email: '',
        },
        nameRules: [
            v => !!v || 'Naam is verplicht'
        ],
        emailRules: [
            v => !!v || 'E-mail is verplicht',
            v => /.+@.+\..+/.test(v) || 'Ongeldig e-mailadres',
        ],
    }),
    watch: {
        $route(route) {
            if (route.params.challengeSlug !== this.challenge.slug || route.params.subchallengeSlug !== this.subchallenge.slug) {
                // nieuwe subchallenge info ophalen
                this.loadSubchallenge(route.params.challengeSlug, route.params.subchallengeSlug)
                    .then(() => {
                        this.loadEntry(route.params.challengeSlug, route.params.subchallengeSlug, route.params.entryToken)
                    })
            } else {
                // alleen nieuwe entry ophalen
                this.loadEntry(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            }
        }
    },
    methods: {
        loadSubchallenge(challengeSlug, subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            return this.$store.dispatch('getSubchallengeDetail', {
                challengeSlug,
                subchallengeSlug
            }).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;
            })
        },
        loadEntry(challengeSlug, subchallengeSlug, entryToken) {
            return this.$store.dispatch('getEntryDetail', {challengeSlug, subchallengeSlug, entryToken}).then((entry) => {
                this.entry = entry;
                this.isLoaded = true;
            })
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.isSubmitting = true;
                this.$store.dispatch('voteEntry', { entry: this.entry, vote: this.vote })
                    .then(result => {
                        this.isSubmitted = result.success;

                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 422) {
                            if (err.response.data.error === 'max_vote_count') {
                                this.errorMessage = 'Je kunt maximaal 3 stemmen binnen deze deelchallenge uitbrengen.';
                            } else if (err.response.data.error === 'already_voted_entry') {
                                this.errorMessage = 'Je hebt al op deze inzending gestemd.';
                            } else {
                                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Controleer je invoer'});
                            }
                        } else {
                            return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Door een serverfout kon je stem niet verwerkt worden'});
                        }
                    })
                    .finally(() =>{
                        this.isSubmitting = false;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
