<template>
    <div class="tile">
        <router-link :to="route">
            <div class="graphic rounded-lg">
<!--            TODO: image -->
                <v-img
                  :src="challenge.tileImage.url"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
<!--                TODO: check for black or white text -->
                <strong>
                    {{challenge.name}}
                    <span class="text-script" v-if="!challenge.hideChallengeText">challenge</span>
                </strong>
            </div>
            <p>{{challenge.subtitle}}</p>
            <p class="status">
                <v-icon
                    :color="statusColor"
                    class="mr-1"
                >
                    fa fa-circle
                </v-icon>
                {{ challenge.subtitleCta }}
            </p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ChallengeTile",
    props: {
        challenge: {
            type: Object,
            required: true
        }
    },
    computed: {
        statusColor() {
            return (this.challenge.isActive) ? 'success' : 'warning'
        },
        statusLabel () {
            return (this.challenge.isActive) ? 'Live' : 'Binnenkort'
        },
        route () {
            if (this.challenge.slug === 'spelshow') {
                return {name:'SpelshowLanding'}
            } else {
                return {name:'Challenge', params: {slug: this.challenge.slug}}
            }
        }
    }
}
</script>

<style scoped>

</style>
