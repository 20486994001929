<template>
    <v-container>
        <h1 class="text-h1 text-script mb-8">Alle challenges op een rij</h1>

        <h2 class="text-h2 heading-border" v-if="activeChallenges.length >= 1">
            <v-icon
                color="success"
                class="mr-2"
                x-small
            >
                fa fa-circle
            </v-icon>
            <strong>Actieve</strong> challenges
        </h2>
        <ExtendedChallengeTile
            :challenge="challenge"
            v-for="(challenge, index) in activeChallenges"
            :key="challenge.slug"
            :class="{ 'last': index === activeChallenges.length - 1 }"
        ></ExtendedChallengeTile>


        <h2 class="text-h2 heading-border" v-if="futureChallenges.length >= 1">
            <v-icon
                color="warning"
                class="mr-2"
                x-small
            >
                fa fa-circle
            </v-icon>
            <strong>Afgesloten</strong> challenges
        </h2>
        <ExtendedChallengeTile
            :challenge="challenge"
            v-for="(challenge, index) in futureChallenges"
            :key="challenge.slug"
            :class="{ 'last': index === futureChallenges.length - 1 }"
        ></ExtendedChallengeTile>

        <!-- TODO: hoe werkt meedoen -->
        <div v-if="false">
            <h2 class="text-h2 font-weight-bold">Hoe werkt meedoen?</h2>
            <p>Meedoen kan vanaf maandag xx februari 2021 t/m oktober 2020.</p>
            <ol class="decorative pl-3 mb-5">
                <li class="mb-2">List item</li>
                <li class="mb-2">List item</li>
                <li>List item</li>
            </ol>

            <p class="kijk-hoe-het-werkt">
                <img
                    src="../assets/images/kijk-hoe-het-werkt.svg"
                />
            </p>
        </div>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ExtendedChallengeTile from "../components/ExtendedChallengeTile";
import BottomContent from "../components/BottomContent";

export default {
    name: "Challenges",
    components: {ExtendedChallengeTile, BottomContent},
    mounted () {
        this.$store.dispatch('getChallenges')
    },
    computed: {
        activeChallenges() {
            return this.$store.getters.getActiveChallenges
        },
        futureChallenges() {
            return this.$store.getters.getFutureChallenges
        },
        ...mapState({
            challenges: state => state.challenges.challenges
        })
    }
}
</script>

<style scoped>

</style>
