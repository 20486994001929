<template>
    <div class="tile extended">
        <router-link :to="{name:'Challenge', params: {slug: challenge.slug}}">
            <div class="graphic rounded-lg mr-3">
<!--            TODO: image -->
                <v-img
                  :src="challenge.tileImage.url"
                  aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
            </div>
            <div class="content">
                <h2><strong>{{challenge.name}}</strong> Challenge</h2>
                <p class="subtitle">{{challenge.subtitle}}</p>

                <p class="status">
                    <v-icon
                        :color="statusColor"
                        class="mr-1"
                    >
                        fa fa-circle
                    </v-icon>
                    {{ challenge.subtitleCta }}
                </p>

    <!--            TODO: highlights -->
                <ul v-if="false">
                    <li>
                        <v-icon
                            color="success"
                            small
                            class="mr-2"
                        >
                            fas fa-check
                        </v-icon>
                        4 opdrachten
                    </li>
                    <li>
                        <v-icon
                            color="success"
                            small
                            class="mr-2"
                        >
                            fas fa-check
                        </v-icon>
                        4 opdrachten
                    </li>
                </ul>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "ExtendedChallengeTile",
    props: {
        challenge: {
            type: Object,
            required: true
        }
    },
    computed: {
        statusColor() {
            return (this.challenge.isActive) ? 'success' : 'warning'
        },
        statusLabel () {
            return (this.challenge.isActive) ? 'Live' : 'Binnenkort'
        }
    }
}
</script>

<style scoped>

</style>
