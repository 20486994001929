<template>
    <v-container
        :class="{ 'pt-8': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row
            :class="{ 'pb-5': $vuetify.breakpoint.mdAndUp, 'align-center': $vuetify.breakpoint.mdAndUp }"
        >
            <v-col cols="12" md="6" class="pb-0 pt-5">
                <!--<p class="text-h2 text-script mb-0">Doe, denk, sport, kook en verras mee!</p>-->

                <p v-if="hasBanner" class="banner-cta mb-4">
                    <router-link :to="{name:'Challenge', params: {slug: featuredChallenge.slug}}">
                        <v-img
                            :src="featuredChallenge.bannerImage.url"
                            height="90"
                            cover
                            class="rounded-lg"
                        >
                        </v-img>
                        <v-icon
                        >
                            far fa-angle-right
                        </v-icon>
                    </router-link>
                </p>

                <v-row no-gutters class="mb-4">
                    <v-col class="pr-2">
                        <v-btn
                            class="black--text"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                            block
                            :to="{name: 'Submissions'}"
                        >
                            Bekijk de inzendingen
                        </v-btn>
                    </v-col>
                    <v-col class="pl-2">
                        <v-btn
                            class="black--text"
                            color="primary"
                            depressed
                            rounded
                            block
                            :to="{name: 'Challenges'}"
                        >
                            De Challenges
                        </v-btn>
                    </v-col>
                </v-row>

                <p v-if="config">{{ config['home.text'] }}</p>
            </v-col>
        </v-row>

<!--1208eb8353-->
        <VimeoVideo :video-id="626316107" class="mb-3 rounded-lg"></VimeoVideo>


        <v-divider class="mb-4 mt-4"></v-divider>

        <NewsSummary max-items="10" compact>
            <h2 class="text-h1">De leukste <strong>nieuwtjes</strong></h2>
        </NewsSummary>


        <h2 class="text-h1" v-if="false"><strong>Challenges</strong> voor jou</h2>
        <swiper
        v-if="false"
            :options="swiperOptions"
        >
            <swiper-slide
                v-for="challenge in activeChallenges"
                :key="challenge.slug"
            >
                <ChallengeTile
                    :challenge="challenge"
                ></ChallengeTile>
            </swiper-slide>
            <swiper-slide
                v-if="activeChallenges.length < 2"
            >
                <div class="challenge-placeholder rounded-lg">
                    <span class="text-script">Andere challenges<br />starten snel!</span>
                </div>
            </swiper-slide>
        </swiper>

<!--         <v-divider class="mb-4 mt-4" v-if="false"></v-divider>

        <NewsSummary offset="3" compact>
            <h2 class="text-h1">Nog meer <strong>nieuwtjes</strong></h2>
            <v-divider class="mb-4 mt-4"></v-divider>
        </NewsSummary> -->

        <VimeoVideo :video-id="510791624" class="mb-3 rounded-lg"></VimeoVideo>

        <BottomContent></BottomContent>
    </v-container>
</template>

<script>
import ChallengeTile from "../components/ChallengeTile";
import BottomContent from "../components/BottomContent";
import VimeoVideo from "../components/VimeoVideo";
import {mapState} from 'vuex';

import NewsSummary from "../components/NewsSummary";

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'Home',
    components: {
        NewsSummary,
        ChallengeTile,
        BottomContent,
        VimeoVideo,
        Swiper,
        SwiperSlide
    },
    data: () => ({
        video: {
            id: 510791624
        },
        swiperOptions: {
            slidesPerView: 'auto',
            autoplay: {
                delay: 3500,
                disableOnInteraction: false
            }
        }
    }),
    mounted() {
        this.$store.dispatch('getChallenges')
    },
    computed: {
        activeChallenges() {
            return [{
                slug: 'spelshow',
                tileImage: {
                    url: '/img/spelshow-tile.jpg'
                },
                name: 'Spelshow',
                subtitle: 'Ga de strijd aan tijdens het teamuitje van het jaar.',
                subtitleCta: 'Schrijf je in',
                isActive: true,
                hideChallengeText: true
            }, ...this.$store.getters.getActiveChallenges]
        },
        futureChallenges() {
            return this.$store.getters.getFutureChallenges
        },
        ...mapState({
            config: state => state.config.config
        }),
        hasBanner () {
            return this.activeChallenges.some(c => c.isFeatured && c.bannerImage && c.bannerImage.url)
        },
        featuredChallenge () {
            return this.activeChallenges.find(c => c.isFeatured && c.bannerImage && c.bannerImage.url)
        }
    }
}
</script>
