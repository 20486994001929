import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import nl from 'vuetify/es5/locale/nl'


export default new Vuetify({
    lang: {
        locales: {nl},
        current: 'nl'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#fdc513',
                accent: '#ff9300',
                secondary: '#ffd960',
                success: '#0fc647',
                info: '#2196F3',
                warning: '#ec7022',
                error: '#ed4956'
            },
        },
    },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fas fa-chevron-down',
        },
    },
});
