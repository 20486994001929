import apiClient from "@/api/apiClient";
import Vue from 'vue';

// initial state
const state = {
    challenges: [],
    likes: {},
    entryConfig: null
}

// getters
const getters = {
    getActiveChallenges (state) {
        return state.challenges.filter(c => c.isActive).sort((a, b) => { return a.displayOrder - b.displayOrder; });
    },
    getFutureChallenges (state) {
        return state.challenges.filter(c => !c.isActive).sort((a, b) => { return a.displayOrder - b.displayOrder; });
    },
    hasLikedEntry: (state) => (entry) => {
        return Object.keys(state.likes).includes(entry.token);
    }
}

// actions
const actions = {
    getChallenges({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.challenges.length >= 1) {
            return state.challenges;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/challenges')
            .then(response => {
                commit('SET_CHALLENGES', response.data);
                return response.data;
            })
    },
    getChallengeDetail(_, {slug}) {
        return apiClient.get('/challenges/'+slug)
            .then(response => {
                return response.data
            })
    },
    getSubchallengeDetail(_, {challengeSlug, subchallengeSlug}) {
        return apiClient.get('/challenges/'+challengeSlug+'/'+subchallengeSlug)
            .then(response => {
                return response.data
            })
    },
    getEntryConfig({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.entryConfig !== null) {
            return state.entryConfig;
        }
        return apiClient.get('/entries/config')
            .then(response => {
                commit('SET_ENTRY_CONFIG', response.data);
                return response.data;
            })
    },
    submitEntry({ dispatch }, { subchallenge, entry }) {
        return apiClient.post('/challenges/'+subchallenge.challenge.slug+'/'+subchallenge.slug+'/submit', {
            entry: entry
        }).then(response => {
            return response.data;
        }).catch(error => {
            if (error.response) {
                switch (error.response.status) {
                    case 422: // form fout
                        dispatch('showSnackbar', { text: 'Controleer je formulier', color: 'error' })
                        break;
                    default:
                        dispatch('showSnackbar', { text: 'Je inzending kon door een serverfout niet verwerkt worden', color: 'error' })
                        break;
                }

            } else {
                dispatch('showSnackbar', { text: 'Je inzending kon niet verzonden worden naar de server', color: 'error' })
            }
            return false;
        })
    },
    getEntryDetail(_, {challengeSlug, subchallengeSlug, entryToken}) {
        return apiClient.get('/challenges/'+challengeSlug+'/'+subchallengeSlug+'/entries/'+entryToken)
            .then(response => {
                return response.data
            })
    },
    getSubchallengeEntries(_, {challengeSlug, subchallengeSlug}) {
        // TODO: Cache local
        return apiClient.get('/challenges/'+challengeSlug+'/'+subchallengeSlug+'/entries')
            .then(response => {
                return response.data
            })
    },
    getChallengeEntries(_, {challengeSlug}) {
        // TODO: Cache local
        return apiClient.get('/challenges/'+challengeSlug+'/entries')
            .then(response => {
                return response.data
            })
    },
    getAllEntries() {
        return apiClient.get('/entries')
            .then(response => {
                return response.data
            })
    },
    getVideoUploadUrl(_, {challengeSlug, subchallengeSlug, size, description}) {
        return apiClient.post('/challenges/'+challengeSlug+'/'+subchallengeSlug+'/upload-url', {
            size: size,
            description: description
        })
            .then(response => {
                return response.data
            })
    },
    likeEntry({commit}, { entry }) {
        return apiClient.post('/challenges/'+entry.subchallenge.challenge.slug+'/'+entry.subchallenge.slug+'/entries/'+entry.token+'/like')
            .then(response => {
                commit('LIKE_ENTRY', { entryToken: entry.token, likeToken: response.data.token });
                return response.data;
            });

    },
    unlikeEntry({ commit, state }, { entry}) {
        if (!state.likes[entry.token]) {
            return null;
        }
        const likeToken = state.likes[entry.token];
        return apiClient.delete('/challenges/'+entry.subchallenge.challenge.slug+'/'+entry.subchallenge.slug+'/entries/'+entry.token+'/likes/'+likeToken)
            .then(response => {
                commit('UNLIKE_ENTRY', { entryToken: entry.token, likeToken: likeToken });
                return response.data;
            })
            .catch(() => {
                commit('UNLIKE_ENTRY', { entryToken: entry.token, likeToken: likeToken });
                return { count: entry.likeCount };
            });
    },
    voteEntry(_, { entry, vote }) {
        return apiClient.post('/challenges/'+entry.subchallenge.challenge.slug+'/'+entry.subchallenge.slug+'/entries/'+entry.token+'/vote', { vote })
            .then(response => {
                return response.data;
            });
    },
    confirmVote(_, { entry, voteToken }) {
        return apiClient.post('/challenges/'+entry.subchallenge.challenge.slug+'/'+entry.subchallenge.slug+'/entries/'+entry.token+'/confirm/'+voteToken)
            .then(response => {
                return response.data;
            });
    }
}


// mutations
const mutations = {
    SET_CHALLENGES(state, challenges) {
        state.challenges = challenges;
    },
    LIKE_ENTRY(state, { entryToken, likeToken }) {
        Vue.set(state.likes, entryToken, likeToken);
    },
    UNLIKE_ENTRY(state, { entryToken }) {
        delete state.likes[entryToken]
    },
    SET_ENTRY_CONFIG(stae, entryConfig) {
        state.entryConfig = entryConfig
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
