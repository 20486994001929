<template>
    <div class="vimeo-video">
        <div class="video" :class="{ 'hasThumbnail': thumbnail, 'isPlaying': isPlaying }">
            <v-img
                :src="thumbnail"
                v-if="thumbnail"
                class="placeholder-img"
            ></v-img>
            <vueVimeoPlayer
                ref="player"
                :video-id="videoId"
                :options="options"
                @pause="onPause"
                @ended="onEnded"
            ></vueVimeoPlayer>
            <span class="play-icon" @click="togglePlay"></span>
        </div>
        <div class="video-meta" :class="metaClass" v-if="title || subtitle">
            <strong v-if="title">{{ title }}</strong>
            <span v-if="subtitle">{{ subtitle }}</span>
        </div>
    </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
    name: "InlineVimeoVideo",
    props: {
        videoId: {
            //type: String,
            required: true
        },
        thumbnail:  {
            type: String,
            required: false
        },
        title:  {
            type: String,
            required: false
        },
        subtitle:  {
            type: String,
            required: false
        },
        metaClass: {
            type: String,
            required: false,
            default: ''
        }
    },
    components: {vueVimeoPlayer},
    data: () => ({
        isPlaying: false,
        options: {
            byline: false,
            title: false,
            controls: true
        }
    }),
    mounted () {
        this.options.color = this.$vuetify.theme.themes.light.primary
    },
    methods: {
        onPause() {
			this.pause()
		},
        onEnded() {
            this.$emit('videoend')
        },
        togglePlay () {
            if(this.isPlaying) {
                this.pause()
            } else {
                this.play()
            }
        },
        play () {
            this.isPlaying = true
            this.$refs.player.play()
        },
        pause () {
            this.isPlaying = false
            this.$refs.player.pause()
        }
    }
}
</script>

<style scoped>

</style>
