<template>
    <v-container class="spelshow-landing">
        <div class="spelshow-container">
            <div class="spelshow-logo">
                <v-img src="@/assets/images/spelshow-logo.png" :max-width="234" :max-height="124"></v-img>
            </div>

            <div v-if="isSubmitted" class="text-center">
                <v-img
                    src="https://media.giphy.com/media/K6D7melVjXhe87mbmI/giphy.gif"
                    aspect-ratio="1"
                    class="mb-6 rounded-lg mx-auto"
                    max-width="300px"
                >
                </v-img>
                <h2 class="text-h1 mb-6 mt-1">Upload <strong>gelukt!</strong></h2>
                <p>Bedankt voor je inzending</p>
            </div>
            <div v-else>
                <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
                <div v-if="config && config['spelshow.doeopdracht']" v-html="config['spelshow.doeopdracht']"></div>

                <v-form
                    ref="form"
                    v-model="isValid"
                    lazy-validation
                    @submit="submit"
                >
                    <v-text-field
                        v-model="entry.teamName"
                        :rules="teamNameRules"
                        :disabled="isSubmitting"
                        placeholder="Teamnaam"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.contactName"
                        :rules="contactNameRules"
                        :disabled="isSubmitting"
                        placeholder="Contactpersoon (voor- en achternaam)"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.email"
                        :rules="emailRules"
                        :disabled="isSubmitting"
                        placeholder="E-mailadres"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.phoneNumber"
                        :rules="phoneNumberRules"
                        :disabled="isSubmitting"
                        placeholder="Telefoonnummer"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>
                    <v-text-field
                        v-model="entry.teamAddress"
                        :rules="teamAddressRules"
                        :disabled="isSubmitting"
                        placeholder="Adresgegevens van jullie locatie op de avond zelf"
                        required
                        outlined
                        rounded
                        dense
                        hide-details
                        class="my-3"
                    ></v-text-field>

                    <div class="custom-upload-field mt-3"
                         :class="{'mb-5': !showVideoWarning}"
                    >
                        <label>Video</label>
                        <v-file-input v-model="video"></v-file-input>
                        <v-btn
                            :disabled="isSubmitting"
                            class="black--text white"
                            color="grey lighten-1"
                            depressed
                            rounded
                            outlined
                        >
                            Selecteer
                        </v-btn>
                    </div>
                    <p class="error--text mb-5 mt-2" v-if="showVideoWarning">Verplicht</p>

                    <v-progress-linear
                        color="light-green"
                        height="25"
                        class="rounded-lg my-5"
                        striped
                        :value="videoUploadProgress"
                        v-if="videoUploadProgress !== null"
                    >
                        <template v-slot:default="{ value }">
                            <strong>Bezig met uploaden: {{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>


                    <v-btn
                        class="black--text"
                        color="error"
                        :disabled="isSubmitting"
                        block
                        depressed
                        rounded
                        @click="submit"
                    >
                        Inzenden
                    </v-btn>
                </v-form>
            </div>
        </div>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import apiClient from "@/api/apiClient";
import Uppy from '@uppy/core'
const Tus = require('@uppy/tus')


export default {
    name: "SpelshowDoeOpdracht2",
    mounted () {
        this.uppy = new Uppy().use(Tus, {
            resume: true,
            retryDelays: [0, 1000, 3000, 5000],
        });
        this.uppy.on('progress', (progress) => {
            this.videoUploadProgress = progress;
        })
    },
    data: () => ({
        isSubmitted: false,
        isSubmitting: false,
        isValid: false,
        uppy: null,
        videoUploadProgress: null,
        entry: {
            teamName: null,
            contactName: null,
            email: null,
            phoneNumber: null,
            vimeoUrl: null,
            teamAddress: null
        },
        video: null,
        teamNameRules: [v => !!v || 'Verplicht'],
        contactNameRules: [v => !!v || 'Verplicht'],
        emailRules: [v => !!v || 'Verplicht'],
        phoneNumberRules: [v => !!v || 'Verplicht'],
        videoRules: [v => !!v || 'Verplicht'],
        teamAddressRules: [v => !!v || 'Verplicht'],
    }),
    computed: {
        showVideoWarning() {
            return !this.video
        },
        ...mapState({
            config: state => state.config.config
        })
    },
    methods: {
        submit() {
            if (this.$refs.form.validate() && !this.showVideoWarning && !this.showFileWarning) {
                this.doSubmit();
            }
        },
        doSubmit() {

            this.isSubmitting = true;
            this.uploadVideo(this.video).then(result => {
                this.entry.vimeoUrl = result.link

                return apiClient.post('/spelshow/signup2', {entry: this.entry});
            }).then(response => {
                this.isSubmitted = response.data.success;
                window.scrollTo({top: 0, behavior: 'smooth'});
            }).finally(() => {
                this.isSubmitting = false;
            })
        },
        uploadVideo (file) {
            console.log('%cvideo upload%c retrieving upload url for ', 'color: #B10DC9', 'color: #33333', file);
            return this.$store.dispatch('getVideoUploadUrl', {
                challengeSlug: 'spelshow',
                subchallengeSlug: 'doeopdracht-2',
                size: file.size,
                description: this.entry.teamName
            })
                .then(uploadTicket => {
                    console.log('%cvideo upload%c got url ' + uploadTicket.upload_url, 'color: #B10DC9', 'color: #33333');

                    let tusPlugin = this.uppy.getPlugin('Tus');
                    tusPlugin.opts.endpoint = uploadTicket.upload_url;
                    tusPlugin.opts.uploadUrl = uploadTicket.upload_url;

                    try {
                        this.uppy.addFile({
                            source: 'file input',
                            name: file.name,
                            type: file.type,
                            data: file
                        })
                        console.log('%cvideo upload%c added file to uppy', 'color: #B10DC9', 'color: #33333');
                    } catch (err) {
                        if (err.isRestriction) {
                            // handle restrictions
                            console.log('Restriction error:', err)
                        } else {
                            // handle other errors
                            console.error(err)
                        }
                        throw err;
                    }

                    console.log('%cvideo upload%c starting upload', 'color: #B10DC9', 'color: #33333');
                    return this.uppy.upload().then(result => {
                        console.log('%cvideo upload%c upload success', 'color: #B10DC9', 'color: #33333', result);
                        return uploadTicket
                    })
                })
        }
    }
}
</script>

<style scoped>

</style>
