<template>
    <v-footer app class="pa-0">
        <v-bottom-navigation elevation="0">
            <NavigationButtons></NavigationButtons>
        </v-bottom-navigation>
    </v-footer>
</template>

<script>
import NavigationButtons from "@/components/NavigationButtons";
export default {
    name: "Footer",
    components: {NavigationButtons}
}
</script>

<style scoped>

</style>
