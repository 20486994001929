<template>
    <div class="like-container">
        <v-btn
            class="black--text px-7 mx-auto mb-2"
            color="grey lighten-1"
            depressed
            large
            outlined
            rounded
            @click="like()"
            v-if="button"
        >
            <v-icon
                :color="isLiked ? 'primary': 'grey darken-1'"
            >
                {{isLiked ? 'fas fa-heart' : 'fal fa-heart'}}
            </v-icon>
        </v-btn>
        <br v-if="button" />
        <span class="count" v-if="button">{{visibleLikeCount}}</span>

        <div
            @click="like()"
            class="like-icon"
            v-if="!button"
        >
            <img
                width="70"
                src="../assets/images/heart.svg"
            />
            <v-icon x-small v-if="isLiked">
                fa fa-check
            </v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntryLike",
    props: {
        entry: {
            type: Object,
            required: true
        },
        button: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        likedLocally: null,
        overridedLikeCount: null,
    }),
    computed: {
        isLiked() {
            if (this.likedLocally !== null) {
                return this.likedLocally;
            }
            return this.$store.getters.hasLikedEntry(this.entry);
        },
        visibleLikeCount() {
            if (this.overridedLikeCount !== null) {
                return this.overridedLikeCount;
            }
            return this.entry.likeCount;
        }
    },
    methods: {
        like() {
            if (this.isLiked) {
                this.likedLocally = false;
                this.$store.dispatch('unlikeEntry', {entry: this.entry}).then((result) => {
                    this.likedLocally = null;
                    this.overridedLikeCount = result.count;
                });

            } else {
                this.likedLocally = true;
                this.$store.dispatch('likeEntry', {entry: this.entry}).then((result) => {
                    this.likedLocally = null;
                    this.overridedLikeCount = result.count;
                });

            }
        }
    }
}
</script>

<style scoped>

</style>
