<template>
    <div
        class="tile subchallenge"
        :class="{ active: subchallenge.isActive, 'gray-text': !subchallenge.isActive && challengeSlug === 'nieuweblikken' }"
    >
        <router-link
            :to="{name:'Subchallenge', params: { challengeSlug: challengeSlug, subchallengeSlug: subchallenge.slug }}"
            :event="subchallenge.isActive ? 'click' : ''"
            :disabled="!subchallenge.isActive"
        >
            <div class="graphic rounded-lg">
                <v-img
                  :src="subchallenge.tileImage.url"
                  :style="(challengeSlug === 'nieuweblikken') ? 'bottom:15px' : ''"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
<!--                TODO: check for black or white text -->
                <strong>{{subchallenge.shortTitle}}</strong>
            </div>
            <h2>{{subchallenge.name}}</h2>
            <p v-if="subchallenge.subtitle">{{ subchallenge.subtitle }}</p>
            <p class="status">
                <v-icon
                    :color="statusColor"
                    class="mr-1"
                >
                    fa fa-circle
                </v-icon>
                {{ subchallenge.statusLabel }}
            </p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SubchallengeTile",
    props: {
        subchallenge: {
            type: Object,
            required: true
        },
        challenge: {
            type: Object,
            required: false
        }
    },
    computed: {
        challengeSlug () {
            if (this.subchallenge.challenge) {
                return this.subchallenge.challenge.slug
            } else {
                return this.challenge.slug
            }
        },
        statusColor() {
            return (this.subchallenge.isActive) ? 'success' : 'warning'
        }
    }
}
</script>

<style scoped>

</style>
