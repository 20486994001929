<template>
    <div class="tile entry mb-5">
        <router-link :to="{name:entryRoute, params: {challengeSlug: challengeSlug, subchallengeSlug: subchallengeSlug, entryToken: entry.token}}">
            <div class="rank" v-if="rank">
                <span>{{rank}}</span>
            </div>
            <div class="graphic rounded-lg mr-3">
                <v-img
                    v-if="entry.image && entry.image.url"
                    :src="entry.image.url"
                    max-height="120"
                    class="rounded-lg"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
                <v-chip
                    label
                    small
                    :color="counterMode === 'votes' ? 'primary' : 'success'"
                    class="px-2 font-weight-bold"
                    v-if="counterValue > 0"
                >
                    +{{ counterValue }}
                </v-chip>
            </div>
            <div class="content">
                <h2 class="mb-5">{{entry.description}}</h2>
                <p class="mb-0">{{entry.lastName}} - {{entry.shopName}}</p>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "EntryTile",
    props: {
        entry: {
            type: Object,
            required: true
        },
        challenge: {
            type: Object,
            required: false
        },
        subchallenge: {
            type: Object,
            required: false
        },
        rank: {
          type: Number,
          required: false
        },
        counterMode: {
            type: String,
            default: null
        }
    },
    computed: {
        entryRoute () {
            if (this.challengeSlug === 'deuitblinkers') {
                return 'BattleEntry'
            } else {
                return 'Entry';
            }
        },
        challengeSlug() {
            if (this.challenge) {
                return this.challenge.slug
            } else {
                return this.entry.subchallenge.challenge.slug;
            }
        },
        subchallengeSlug() {
            if (this.subchallenge) {
                return this.subchallenge.slug
            } else {
                return this.entry.subchallenge.slug;
            }
        },
        counterValue() {
            if (this.counterMode === 'votes' || this.entry.isVotingEnabled) {
                return this.entry.voteCount;
            } else {
                return this.entry.likeCount;
            }
        }
    }
}
</script>

<style scoped>

</style>
