<template>
    <v-container class="pa-0" :class="{ 'pt-5' : !showLinks }">
        <FooterLinks v-if="showLinks"></FooterLinks>

        <div class="text-center py-5 mt-2">
            <v-img
                contain
                height="100"
                src="../assets/images/since-1921-yellow.svg"
                class="mb-1"
            ></v-img>
            <span class="text-mindset">We zijn pas nét begonnen!</span>
        </div>
    </v-container>
</template>

<script>
import FooterLinks from "../components/FooterLinks";

export default {
    name: "BottomContent",
    props: {
        showLinks: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {FooterLinks},
}
</script>

<style scoped>

</style>
