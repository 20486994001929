// initial state
import apiClient from "@/api/apiClient";

const state = {
    isLoggedIn: false,
    isSpelshowAuthed: false,
    password: null
}

// getters
const getters = {

}

// actions
const actions = {
    login({commit}, { password }) {
        commit('SET_AUTH_STATE', { isLoggedIn: false})
        return apiClient.post('/login', { password: password })
            .then(() => {
                commit('SET_AUTH_STATE', {isLoggedIn: true, password: password })
                return true;
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    return false;
                } else {
                    throw error;
                }
            })
    },
    spelshowLogin({commit}, { password }) {
        commit('SET_SPELSHOW_AUTH', false)
        return apiClient.post('/spelshow-login', { password: password })
            .then((response) => {
                if (response.data.success) {
                    commit('SET_SPELSHOW_AUTH', true)
                    return true;
                } else {
                    return false;
                }
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    return false;
                } else {
                    throw error;
                }
            })
    },
    logout({commit}) {
        commit('CLEAR_AUTH_STATE');
    }
}

// mutations
const mutations = {
    SET_AUTH_STATE(state, { isLoggedIn, password }) {
        state.isLoggedIn = isLoggedIn;
        state.password = password;
    },
    CLEAR_AUTH_STATE(state) {
        state.isLoggedIn = false;
        state.password = null;
        state.isSpelshowAuthed = false;
    },
    SET_SPELSHOW_AUTH(state, isSpelshowAuthed) {
        state.isSpelshowAuthed = isSpelshowAuthed;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
