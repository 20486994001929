<template>
    <v-container>
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
                <div v-if="config" v-html="config['contact.text']"></div>

                <BottomContent></BottomContent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import BottomContent from "../../components/BottomContent";

export default {
    name: "Contact",
    components: {BottomContent},
    computed:{
        ...mapState({
            config: state => state.config.config
        })
    }
}
</script>

<style scoped>

</style>
