<template>
    <v-container v-if="subchallenge">
            <router-link
                v-if="subchallenge && entry"
                :to="{name: 'Entry', params: {
                            challengeSlug: challenge.slug,
                            subchallengeSlug: subchallenge.slug,
                            entryToken: entry.token
                        }}"
                class="back-link mb-3">
                <span class="mr-1">&lt;</span> Terug
            </router-link>

        <h2 class="text-h1 mb-5 mt-1 text-center">Bedankt voor je stem</h2>
        <v-img
            src="https://media.giphy.com/media/ZZr97FVxIMiPGPT9s6/giphy.gif"
            aspect-ratio="1"
            class="mb-6 rounded-lg mx-auto"
            max-width="300px"
        >
        </v-img>
        <p class="text-center">We hebben je stem verwerkt.</p>

        <p class="text-center" v-if="$route.params.challengeSlug === 'nieuweblikken'">
            Op woensdag 16 juni van 10.30 - 12.00 uur worden de 10 ideeën gepitcht, en jij kunt live meekijken! 
            <a href="https://www.jumbo-events.nl/1081" target="_blank">Meld je hier snel voor aan!</a>
        </p>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";

export default {
    name: "EntryForm",
    components: {
        BottomContent
    },
    mounted() {
        this.loadSubchallenge(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug)
            .then(() => {
                return this.loadEntry(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            })
            .then(() => {
                return this.confirmVote(this.$route.params.voteToken)
            })
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false,
        entry: null,
    }),
    methods: {
        loadSubchallenge(challengeSlug, subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            return this.$store.dispatch('getSubchallengeDetail', {
                challengeSlug,
                subchallengeSlug
            }).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;
            })
        },
        loadEntry(challengeSlug, subchallengeSlug, entryToken) {
            return this.$store.dispatch('getEntryDetail', {challengeSlug, subchallengeSlug, entryToken}).then((entry) => {
                this.entry = entry;
            })
        },
        confirmVote(voteToken) {
            return this.$store.dispatch('confirmVote', { entry: this.entry, voteToken})
            .then(() => {
                this.isLoaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
