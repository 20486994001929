<template>
    <v-container v-if="coach">
        <div class="d-flex align-center mb-4">
            <div>
                <router-link :to="{name:'Subchallenge', params: { challengeSlug: 'nieuweblikken', subchallengeSlug: 'stap-2' }}" class="back-link mb-3">
                    <span class="mr-1">&lt;</span> Alle coaches
                </router-link>

                <h1 class="text-h1"><strong>{{coach.name}}</strong></h1>
            </div>

            <v-avatar
                v-if="coach.image"
                size="60"
                class="ml-auto"
            >
                <v-img
                    :src="coach.image.url"
                >
                </v-img>
            </v-avatar>
        </div>

        <div v-html="coach.introduction"></div>

        <!--        TODO: subscribe block (wellicht component van maken aangezien die ook op Coaches.vue gebruikt wordt? -->
        <h2 class="text-h2 font-weight-bold pt-4">Deze coach inschakelen?</h2>
        <div class="coach-signup">
            <p>Kun jij wel wat denkkracht gebruiken? Laat weten waarom jouw idee deze coach-hulp verdient!</p>
            <v-btn
                class="black--text px-5 ml-10"
                color="primary"
                depressed
                rounded
                :href="coach.signupLink"
                target="_blank"
            >
                Meld je aan!
            </v-btn>
        </div>

        <v-divider class="mb-5 mt-3" v-if="hasVideos"></v-divider>

        <h1 class="text-h1 mb-5" v-if="hasVideos">Handige <strong>video's</strong></h1>
        <VimeoVideo
            :video-id="video.videoId"
            :thumbnail="video.thumbnail"
            class="mx-n3 mx-md-0 mb-5 rounded-video"
            v-for="video in coach.videos"
            :key="'video-' + video.id"
            :title="video.title"
            :subtitle="video.subtitle"
            :meta-class="'px-3'"
        >
        </VimeoVideo>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import VimeoVideo from "../../components/VimeoVideo";
import BottomContent from "../../components/BottomContent";

export default {
    name: "Coach",
    components: {VimeoVideo, BottomContent},
    mounted() {
        this.loadCoach(this.$route.params.slug);
    },
    data: () => ({
        coach: null,
        video: {
            id: '503087711'
        }
    }),
    computed: {
        hasVideos () {
            return this.coach && this.coach.videos && this.coach.videos.length > 0
        }
    },
    methods: {
        loadCoach(slug) {
            // Eerste de snelle versie in de cache opzoeken
            this.coach = this.$store.state.coaches.coaches.find(c => c.slug === slug);

            if (!this.coach) {
                // anders opzoeken via api
                this.$store.dispatch('getCoaches').then(() => {
                    this.coach = this.$store.state.coaches.coaches.find(c => c.slug === slug);
                    this.isLoaded = true;
                })
            } else {
                this.isLoaded = true;
            }
        }
    }
}
</script>

<style scoped>

</style>
