<template>
    <div class="tile-grid my-5" v-if="subchallenges.length > 0">
        <div
            class="tile-item"
            v-for="subchallenge in subchallenges"
            :key="subchallenge.slug"
        >
            <SubchallengeTile :subchallenge="subchallenge" :challenge="challenge"></SubchallengeTile>
        </div>
    </div>
</template>

<script>
import SubchallengeTile from "../components/SubchallengeTile";

export default {
name: "SubchallengeSlider",
    components: {
        SubchallengeTile,
    },
    props: {
        subchallenges: {
            type: Array,
            required: true
        },
        challenge: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
