<template>
    <div class="battle-entry" v-touch="{ up: () => onVideoEnd() }" v-if="entry">
        <VimeoVideo v-if="entry.embed" :videoId="entry.embed.id" @videoend="onVideoEnd"></VimeoVideo>
        <div v-else class="battle-image">
            <img :src="entry.image.url" />
        </div>
        <v-btn
          fab
          dark
          small
          color="black"
          :to="{name:'Subchallenge', params: { challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug }}"
          class="close-btn"
        >
          <v-icon dark>
            fal fa-times
          </v-icon>
        </v-btn>
        <div class="description">
            <div class="top mb-2">
                <span class="swipe text-script">Swipe up</span>
                <div class="buttons ml-auto">
                    <EntryLike :entry="entry" :button="false"></EntryLike>
                    <router-link
                        :to="{name: 'EntryForm', params: { challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug}}"
                        class="cta-btn"
                    >
                        <v-img
                            contain
                            height="30"
                            src="../assets/images/dat-kan-ik-beter.svg"
                        ></v-img>
                    </router-link>
                </div>
            </div>
            <strong>{{ subchallenge.name }}</strong><br />
            <strong>{{ subchallenge.subtitle }}</strong><br />
            {{ entry.firstName }} {{ entry.lastName }}<br />
            {{ entry.shopName }}
        </div>
    </div>
</template>

<script>
import VimeoVideo from "../components/VimeoVideo";
import EntryLike from "../components/EntryLike";

export default {
    name: "BattleEntry",
    components: {
        VimeoVideo,
        EntryLike
    },
    mounted() {
        this.loadSubchallenge(this.$route.params.subchallengeSlug)
            .then(() => {
                this.loadEntry(this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            })
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false,
        entry: null,
    }),
    watch: {
        $route(route) {
            if (route.params.subchallengeSlug !== this.subchallenge.slug) {
                // nieuwe subchallenge info ophalen
                this.loadSubchallenge('deuitblinkers', route.params.subchallengeSlug)
                    .then(() => {
                        this.loadEntry('deuitblinkers', route.params.subchallengeSlug, route.params.entryToken)
                    })
            } else {
                // alleen nieuwe entry ophalen
                this.loadEntry(this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            }
        }
    },
    methods: {
        loadSubchallenge(subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            return this.$store.dispatch('getSubchallengeDetail', {
                challengeSlug: 'deuitblinkers',
                subchallengeSlug
            }).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;
            })
        },
        loadEntry(subchallengeSlug, entryToken) {
            return this.$store.dispatch('getEntryDetail', {
                challengeSlug: 'deuitblinkers',
                subchallengeSlug,
                entryToken
            }).then((entry) => {
                this.entry = entry;
                this.isLoaded = true;
            })
        },
        onVideoEnd() {
            if (this.entry.next) {
                return this.$router.push({
                    name: 'BattleEntry', params: {
                        challengeSlug: this.challenge.slug,
                        subchallengeSlug: this.subchallenge.slug,
                        entryToken: this.entry.next.token
                    }
                })
            } else {
                return this.$router.push({
                    name: 'ChallengeSubmissions', params: {
                        view: 'nieuwste',
                        challengeSlug: this.challenge.slug,
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
