<template>
    <v-container class="login-container align-start" fill-height>
        <div class="login-content">
        <div v-if="config" v-html="config['spelshow.login']" class="mb-7 db-content spelshow-login-text"></div>

        <div class="spelshow-login pt-0">
            <div class="spelshow-divider"></div>
            <v-form
                ref="form"
                v-model="isValid"
                class="pt-5"
                lazy-validation
                @submit.prevent="submit"
            >
                <v-alert type="error" v-if="isInvalidAnswer">Ongeldig antwoord</v-alert>
                <v-text-field
                    ref="passwordField"
                    type="text"
                    placeholder="Typ hier jullie antwoord..."
                    v-model="answer"
                    :rules="answerdRules"
                    class="mt-1 mb-3"
                    dense
                    hide-details
                    outlined
                    required
                    rounded
                ></v-text-field>
                <v-btn
                    block
                    class="white--text"
                    color="#F44336"
                    depressed
                    rounded
                    type="submit"
                >
                    Check jullie antwoord!
                </v-btn>
            </v-form>
            <div class="shadow"></div>
        </div>
            <BottomContent :show-links="false"></BottomContent>

        </div>

    </v-container>
</template>

<script>
import {mapState} from "vuex";
import BottomContent from "../../components/BottomContent";

export default {
    name: "SpelshowLogin",
    components: {
        BottomContent
    },
    data: () => ({
        isValid: true,
        isInvalidAnswer: false,
        answer: '',
        answerdRules: [
            v => !!v || 'Geen antwoord ingevuld'
        ]
    }),
    computed: {
        ...mapState({
            config: state => state.config.config
        })
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch('spelshowLogin', {
                    password: this.answer
                }).then(success => {
                    if (success) {
                        this.$router.push({name: 'SpelshowLanding'})
                    } else {
                        this.isInvalidAnswer = true;
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldig antwoord'});
                    }
                }).catch((e) => {
                    console.error(e);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Serverfout'});
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
