<template>
    <v-container v-if="challenge">
        <router-link :to="{name:'Challenge', params: {slug: challenge.slug}}" class="back-link mb-3">
            <span class="mr-1">&lt;</span> {{ challenge.name }} challenge
        </router-link>

        <h1 class="text-h1 mb-1"><strong>{{ challenge.name }}</strong> Challenge</h1>
        <h2 class="text-h2 text--secondary mb-3" v-if="subchallenge">{{ subchallenge.name }}</h2>

        <VimeoVideo :video-id="subchallenge.videoId" class="mb-3 rounded-lg" v-if="subchallenge.videoId"></VimeoVideo>
        <v-img
            v-if="isLoaded && !subchallenge.videoId"
            :src="headerImage"
            height="75"
            cover
            class="rounded-lg mb-5"
        >
        </v-img>

        <p class="status mb-3" v-if="!isActive">
            <v-icon
                color="warning"
                class="mr-1"
            >
                fa fa-circle
            </v-icon>
            Deze challenge is nog niet actief. Kom later terug!
        </p>

        <div v-html="subchallenge.information" v-if="isLoaded"></div>

        <v-row v-if="hasCoaches">
            <v-col>
                <h2 class="text-h2 font-weight-bold pt-4">Eén van deze coaches bij jou op bezoek? </h2>
                <div class="coach-signup">
                    <p>Kun jij wel wat denkkracht gebruiken? Laat weten waarom jouw idee deze coach-hulp verdient!</p>
                    <v-btn
                        class="black--text px-5 ml-10"
                        color="primary"
                        depressed
                        rounded
                        href="https://www.jumbo-events.nl/959"
                        target="_blank"
                    >
                        Meld je aan!
                    </v-btn>
                </div>

                <v-divider class="mb-5 mt-3"></v-divider>

                <CoachTiles :challenge="challenge"></CoachTiles>
            </v-col>
        </v-row>

       <v-row v-if="isActive && isLoaded && subchallenge.entryType !== 'none'">
            <v-col>
                <v-btn
                    class="black--text"
                    color="primary"
                    block
                    depressed
                    rounded
                    :to="{name: 'EntryForm', params: { challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug}}"
                >
                    {{signupButtonText}}
                </v-btn>
            </v-col>
        </v-row>

        <v-divider class="mb-5 mt-5"></v-divider>

        <EntryList
            :challenge="challenge"
            :subchallenge="subchallenge"
        >
            <template v-slot:header>
                <h2 class="text-h1 mb-4">Andere <strong>inzendingen</strong></h2>
            </template>
        </EntryList>

        <News :challenge="challenge" :subchallenge="subchallenge"></News>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";
import VimeoVideo from "../components/VimeoVideo";
import CoachTiles from "../components/CoachTiles";
import News from "../components/News";
import EntryList from "../components/EntryList";

export default {
    name: "Subchallenge",
    components: {EntryList, News, CoachTiles, BottomContent, VimeoVideo},
    mounted() {
        this.loadSubchallenge(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug);
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false
    }),
    computed: {
        headerImage () {
            if (this.subchallenge.extraImage1) {
                return this.subchallenge.extraImage1.url;
            } else {
                return this.challenge.extraImage2.url;
            }
        },
        isActive() {
            return (!this.challenge || this.challenge.isActive) && (!this.subchallenge || this.subchallenge.isActive);
        },
        hasCoaches() {
            return (this.challenge && this.challenge.slug === 'nieuweblikken') && (this.subchallenge && this.subchallenge.slug === 'stap-2');
        },
        signupButtonText () {
            if (this.subchallenge && (this.subchallenge.slug === 'beachvolleybal' || this.subchallenge.slug === 'voetbal')) {
                return 'Upload je video';
            } else {
                return 'Schrijf je in';
            }
        }
    },
    methods: {
        loadSubchallenge(challengeSlug, subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            this.$store.dispatch('getSubchallengeDetail', {challengeSlug, subchallengeSlug}).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;
                this.isLoaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
