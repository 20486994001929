import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login";
import Challenges from "../views/Challenges";
import Challenge from "../views/Challenge";
import Subchallenge from "../views/Subchallenge";
import EntryForm from "../views/EntryForm";
import Entry from "../views/Entry";
import BattleEntry from "../views/BattleEntry";
import EntryVote from "../views/EntryVote";
import EntryVoteConfirm from "../views/EntryVoteConfirm";
import Submissions from "../views/Submissions";
import Information from "../views/Content/Information";
import Rules from "../views/Content/Rules";
import Prizes from "../views/Content/Prizes";
import Contact from "../views/Content/Contact";
import HomeScreen from "../views/Content/HomeScreen";
import Coach from "../views/NieuweBlikken/Coach";
import SpelshowLogin from "../views/Spelshow/SpelshowLogin";
import SpelshowLanding from "../views/Spelshow/SpelshowLanding";
import SpelshowProgramma from "../views/Spelshow/SpelshowProgramma";
import SpelshowVoorwaarden from "../views/Spelshow/SpelshowVoorwaarden";
import SpelshowDoeOpdracht from "../views/Spelshow/SpelshowDoeOpdracht";
import SpelshowDoeOpdracht2 from "../views/Spelshow/SpelshowDoeOpdracht2";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {requiresAuth: false}
    },
    {
        path: '/challenges',
        name: 'Challenges',
        component: Challenges,
        meta: {requiresAuth: true}
    },
    {
        path: '/inzendingen',
        name: 'Submissions',
        component: Submissions,
        meta: {requiresAuth: true}
    },
    {
        path: '/inzendingen/:view/:challengeSlug',
        name: 'ChallengeSubmissions',
        component: Submissions,
        meta: {requiresAuth: true}
    },
    {
        path: '/informatie',
        name: 'Information',
        component: Information,
        meta: {requiresAuth: true}
    },
    {
        path: '/startscherm',
        name: 'HomeScreen',
        component: HomeScreen,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelregels',
        name: 'Rules',
        component: Rules,
        meta: {requiresAuth: true}
    },
    {
        path: '/prijzen',
        name: 'Prizes',
        component: Prizes,
        meta: {requiresAuth: true}
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow/login',
        name: 'SpelshowLogin',
        component: SpelshowLogin,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow',
        name: 'SpelshowLanding',
        component: SpelshowLanding,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow/programma',
        name: 'SpelshowProgramma',
        component: SpelshowProgramma,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow/voorwaarden',
        name: 'SpelshowVoorwaarden',
        component: SpelshowVoorwaarden,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow/doeopdracht',
        name: 'SpelshowDoeOpdracht',
        component: SpelshowDoeOpdracht,
        meta: {requiresAuth: true}
    },
    {
        path: '/spelshow/doeopdracht-2',
        name: 'SpelshowDoeOpdracht2',
        component: SpelshowDoeOpdracht2,
        meta: {requiresAuth: true}
    },    {
        path: '/:slug',
        name: 'Challenge',
        component: Challenge,
        meta: {requiresAuth: true}
    },
    {
        path: '/nieuweblikken/coaches/:slug',
        name: 'NieuweBlikkenCoach',
        component: Coach,
        meta: {requiresAuth: true}
    },
    {
        path: '/:challengeSlug/:subchallengeSlug',
        name: 'Subchallenge',
        component: Subchallenge,
        meta: {requiresAuth: true}
    },
    {
        path: '/:challengeSlug/:subchallengeSlug/inschrijven',
        name: 'EntryForm',
        component: EntryForm,
        meta: {requiresAuth: true}
    },
    {
        path: '/deuitblinkers/:subchallengeSlug/inzendingen/:entryToken',
        name: 'BattleEntry',
        component: BattleEntry,
        meta: {requiresAuth: true}
    },
    {
        path: '/:challengeSlug/:subchallengeSlug/inzendingen/:entryToken',
        name: 'Entry',
        component: Entry,
        meta: {requiresAuth: true}
    },
    {
        path: '/:challengeSlug/:subchallengeSlug/inzendingen/:entryToken/stem',
        name: 'EntryVote',
        component: EntryVote,
        meta: {requiresAuth: true}
    },
    {
        path: '/:challengeSlug/:subchallengeSlug/inzendingen/:entryToken/bevestig/:voteToken',
        name: 'EntryVoteConfirm',
        component: EntryVoteConfirm,
        meta: {requiresAuth: false}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition)
                }
                if (to.hash) {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth'
                    })
                }
                resolve({x: 0, y: 0})
            }, 500)
        })
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.auth.isLoggedIn) {
            next({
                name: 'Login'
            })
        }
    }
    next() // make sure to always call next()!
})

export default router
