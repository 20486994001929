<template>
    <v-container v-if="subchallenge">
        <router-link
            :to="{name:'Subchallenge', params: {challengeSlug: challenge.slug, subchallengeSlug: subchallenge.slug}}"
            class="back-link mb-3">
            <span class="mr-1">&lt;</span> {{ subchallenge.name }}
        </router-link>

        <v-row v-if="entry" class="submission-details">
            <v-col cols="12" v-if="entry.embed && entry.embed.type === 'vimeo'">
                <VimeoVideo :videoId="entry.embed.id" class="rounded-lg"></VimeoVideo>
            </v-col>
            <v-col cols="12">
                <InstagramEmbed v-if="entry.embed && entry.embed.type === 'instagram'" :url="entry.embed.url" :access-token="facebookAccessToken"></InstagramEmbed>
                <TikTokEmbed v-if="entry.embed && entry.embed.type === 'tiktok'" :url="entry.embed.url"></TikTokEmbed>
                <FacebookEmbed v-if="entry.embed && entry.embed.type === 'facebook_post'" :url="entry.embed.url"  :access-token="facebookAccessToken"></FacebookEmbed>
                <FacebookVideo v-if="entry.embed && entry.embed.type === 'facebook_video'" :url="entry.embed.url"  :access-token="facebookAccessToken"></FacebookVideo>
            </v-col>
            <v-col cols="12" v-if="!entry.embed && entry.image && entry.image.url" class="entry-main-image">
                <img
                    :src="entry.image.url"
                    class="rounded-lg mx-auto"
                />
            </v-col>
            <v-col
                :cols="(subchallenge.isLikesEnabled) ? 8 : 12">

                <h1 class="entry-title mb-3">{{ entry.description }}</h1>

                <ul>
                    <li><span v-if="entry.firstName">{{ entry.firstName }} </span>{{ entry.lastName }}</li>
                    <li>{{ entry.shopName }}</li>
                    <li v-if="entry.remarks">{{ entry.remarks }}</li>
                </ul>
                <p class="font-weight-bold d-flex align-center my-3">
                    <v-icon
                        class="mr-2"
                        color="black"
                        small
                    >
                        fas fa-eye
                    </v-icon>
                    {{entry.viewCount}}
                </p>
            </v-col>
            <v-col class="text-center" cols="4" v-if="subchallenge.isLikesEnabled">
                <EntryLike :entry="entry"></EntryLike>
            </v-col>
            <v-col cols="12">
                <div class="d-flex">
                    <v-btn
                        class="black--text px-5"
                        color="grey lighten-1"
                        depressed
                        rounded
                        outlined
                        v-if="entry.downloadUrl"
                        :href="entry.downloadUrl"
                        target="_blank"
                    >
                        Bekijk recept
                    </v-btn>
                    <v-btn
                        class="black--text ml-2 px-5"
                        color="primary"
                        depressed
                        rounded
                        v-if="subchallenge.isVotingEnabled"
                        :to="{name: 'EntryVote', params: {
                            challengeSlug: challenge.slug,
                            subchallengeSlug: subchallenge.slug,
                            entryToken: entry.token
                        }}"
                    >
                        {{voteButtonText}}
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <v-skeleton-loader v-else type="card"></v-skeleton-loader>

        <v-divider class="my-5"></v-divider>

        <EntryList :challenge="challenge"
                   :subchallenge="subchallenge"
                   :exclude-entry-token="$route.params.entryToken"
        >
            <template v-slot:header>
                <h2 class="text-h1">Andere <strong>inzendingen</strong></h2>
            </template>
        </EntryList>

        <BottomContent :show-links="false"></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../components/BottomContent";
import EntryList from "../components/EntryList";
import InstagramEmbed from "../components/InstagramEmbed";
import TikTokEmbed from "../components/TikTokEmbed";
import FacebookEmbed from "../components/FacebookEmbed";
import FacebookVideo from "../components/FacebookVideo";
import VimeoVideo from "../components/VimeoVideo";
import EntryLike from "../components/EntryLike";

export default {
    name: "Entry",
    components: {
        EntryLike,
        FacebookEmbed, FacebookVideo, TikTokEmbed, InstagramEmbed, EntryList, BottomContent, VimeoVideo},
    mounted() {
        this.loadSubchallenge(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug)
            .then(() => {
                this.loadEntry(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            })
    },
    data: () => ({
        challenge: null,
        subchallenge: null,
        isLoaded: false,
        entry: null,
        facebookAccessToken: process.env.VUE_APP_FACEBOOK_ACCESS_TOKEN
    }),
    computed: {
        voteButtonText () {
            if (this.subchallenge && this.subchallenge.slug === 'delekkerste') {
                return 'Stem op dit recept';
            } else {
                return 'Stem op deze inzending'
            }
        }
    },
    watch: {
        $route(route) {
            if (route.params.challengeSlug !== this.challenge.slug || route.params.subchallengeSlug !== this.subchallenge.slug) {
                // nieuwe subchallenge info ophalen
                this.loadSubchallenge(route.params.challengeSlug, route.params.subchallengeSlug)
                    .then(() => {
                        this.loadEntry(route.params.challengeSlug, route.params.subchallengeSlug, route.params.entryToken)
                    })
            } else {
                // alleen nieuwe entry ophalen
                this.loadEntry(this.$route.params.challengeSlug, this.$route.params.subchallengeSlug, this.$route.params.entryToken)
            }
        }
    },
    methods: {
        loadSubchallenge(challengeSlug, subchallengeSlug) {
            // TODO: Eerste de snelle versie in de cache opzoeken

            // Daarna de rest zoeken
            return this.$store.dispatch('getSubchallengeDetail', {
                challengeSlug,
                subchallengeSlug
            }).then((subchallenge) => {
                this.subchallenge = subchallenge
                this.challenge = subchallenge.challenge;
            })
        },
        loadEntry(challengeSlug, subchallengeSlug, entryToken) {
            return this.$store.dispatch('getEntryDetail', {challengeSlug, subchallengeSlug, entryToken}).then((entry) => {
                this.entry = entry;
                this.isLoaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
