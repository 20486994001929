<template>
    <v-container class="spelshow-landing">
        <div class="spelshow-container">
            <div class="spelshow-logo">
                <v-img src="@/assets/images/spelshow-logo.png" :max-width="234" :max-height="124"></v-img>
            </div>
            <div class="spelshow-divider">
                <div class="label">
                    <span class="white--text">Zaterdag</span><br />
                    <span class="black--text">18 september</span>
                </div>
            </div>

            <h1 class="text-center">
                <span class="black--text">De Jumbo Verrast Spelshow:</span><br />
                <span class="white--text">Hét Teamuitje van het jaar!</span>
            </h1>

            <div v-if="config" v-html="config['spelshow.intro']" class="text-center font-weight-bold mb-3"></div>

            <VimeoVideo v-if="config" :video-id="config['spelshow.video']" class="mb-3"></VimeoVideo>

            <div class="text-center">
                    <!--<router-link :to="{name:'SpelshowProgramma'}" class="button button-bordered mr-3">Programma</router-link>-->
                    <a href="https://www.jumbo-events.nl/1097" target="_blank" class="button button-red">Schrijf je snel in!</a>
            </div>

            <div class="spelshow-divider mt-3"></div>

            <div v-if="config" v-html="config['spelshow.information']" class=""></div>

            <div class="text-center">
                <router-link :to="{name:'SpelshowVoorwaarden'}" class="button button-bordered mr-3">Voorwaarden en informatie</router-link>
                <a href="https://www.jumbo-events.nl/1097" target="_blank" class="button button-red">Schrijf je snel in!</a>
            </div>
        </div>
    </v-container>
</template>

<script>
import VimeoVideo from "../../components/VimeoVideo";
import {mapState} from "vuex";
export default {
    name: "SpelshowLanding",
    components: { VimeoVideo },
    computed: {
        ...mapState({
            config: state => state.config.config
        })
    },
}
</script>

<style scoped>

</style>
