<template>
    <v-app :class="{ 'login-view': this.$route.name == 'Login' }">
        <Header></Header>
        <v-main :class="{ 'battle-entry-wrap': (this.$route.name == 'BattleEntry') }">
            <router-view/>

            <Snackbar></Snackbar>
        </v-main>
        <!--        TODO: check v-if="isLoggedIn"-->
        <Footer v-if="this.$route.name != 'Login' && !$vuetify.breakpoint.lgAndUp"></Footer>

        <v-navigation-drawer
            :mini-variant="true"
            mini-variant-width="80"
            permanent
            fixed
            app
            v-if="this.$route.name != 'Login' && $vuetify.breakpoint.lgAndUp"
        >
            <NavigationButtons></NavigationButtons>
        </v-navigation-drawer>
    </v-app>
</template>

<script>
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Snackbar from "./components/layout/Snackbar";
import NavigationButtons from "@/components/NavigationButtons";

export default {
    name: 'App',

    components: {
        Snackbar,
        Footer,
        Header,
        NavigationButtons
    },

    data: () => ({
        appVersion: process.env.VUE_APP_VERSION
    }),

    created() {
        this.$store.dispatch('getConfig');
    },
    destroyed() {
    },

    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
};
</script>

<style lang="scss">
@import "assets/scss/app"
</style>
