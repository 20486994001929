<template>
    <v-container>
        <!--<h1 class="text-h1 mb-3"><strong>Zo doe je mee</strong> met Jumbo Verrast</h1>
        <v-img
          src="https://picsum.photos/200/200"
          height="100"
          cover
          class="rounded-lg mb-5"
        >
        </v-img>
        <p><strong>Op dit online platform speciaal voor collega’s vind je alles wat je moet weten en nodig hebt om de strijd aan te gaan. Van informatie tot inspiratie en van spelregels tot praktische tips.</strong><br /> Zodra een nieuwe (deel)challenge van start gaat, krijg je bericht via Jumbo TV, de JumboApp voor medewerkers en/of posters op jouw locatie. Ben jij superfanatiek? Doe dan gerust mee met alle challenges! Of pik die ene uitdaging eruit die jou het meeste aanspreekt.</p>
        <v-divider class="mb-4 mt-1"></v-divider>
        <h2 class="text-h2 mb-3">Laat je <strong>coachen</strong></h2>
        <p>Bij een nieuw idee is hulp van buitenaf zeker welkom. Natuurlijk is dit voor je geregeld. Experts en vrienden van Jumbo denken graag mee als coach. Op het platform Jumboverrast.nl zie je per challenge welke coaches voor je klaar staan en bekijk je leuke inspiratievideo’s.</p>
        <VimeoVideo :video-id="video.id" :thumbnail="video.thumbnail" class="mb-3 rounded-lg"></VimeoVideo>

        <p class="mt-1 mb-0 text-center">
            <v-btn
                class="black--text mx-auto px-11"
                color="primary"
                depressed
                rounded
                type="submit"
                :to="{ 'name': 'Challenges' }"
            >
                De challenges
            </v-btn>
        </p>-->

        <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
        <div v-if="config" v-html="config['information.text']" class="mb-7 db-content"></div>

        <BottomContent></BottomContent>
    </v-container>
</template>

<script>
//import VimeoVideo from "../../components/VimeoVideo";
import BottomContent from "../../components/BottomContent";
import {mapState} from "vuex";

export default {
    name: "Information",
    components: {/*VimeoVideo,*/ BottomContent},
    data: () => ({
        video: {
            id: 503087711
        }
    }),
    computed:{
        ...mapState({
            config: state => state.config.config
        })
    }
}
</script>

<style scoped>

</style>
