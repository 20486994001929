<template>
    <v-row v-if="isLoaded">
        <v-col cols="6" v-for="coach in visibleCoaches" :key="coach.slug">
            <div class="tile coach">
                <router-link :to="{name:'NieuweBlikkenCoach', params: { slug: coach.slug }}">
                    <div class="graphic rounded-lg" v-if="coach.image">
                        <v-img
                            :src="coach.image.url"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </div>
                    <strong>{{coach.name}}</strong><br />
                    <span class="text--secondary">{{coach.subtitle}}</span>
                </router-link>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "CoachTiles",
    mounted() {
        this.loadCoaches();
    },
    props: {
        maxCoaches: {
            type: Number,
            required: false
        }
    },
    data: () => ({
        isLoaded: false
    }),
    computed: {
        visibleCoaches () {
            if (this.maxCoaches) {
                return this.coaches.slice(0, this.maxCoaches)
            } else {
                return this.coaches;
            }
        },
        ...mapState({
            coaches: state => state.coaches.coaches
        })
    },
    methods: {
        loadCoaches () {
            this.$store.dispatch('getCoaches').then(() => {
                this.isLoaded = true;
            })
        }
    }
}
</script>

<style scoped>

</style>
