<template>
    <v-app-bar app elevation="0">
        <v-btn
            plain
            icon
            @click="hasHistory()
            ? $router.go(-1)
            : $router.push({ 'name': 'Home' })"
            class="pa-0 back-btn"
            v-if="this.$route.name != 'Home' && this.$route.name != 'Login'"
        >
            <v-icon>
                far fa-angle-left
            </v-icon>
        </v-btn>
        <router-link :to="{name: 'Home' }" class="mx-auto">
            <v-img
                contain
                height="50"
                src="../../assets/images/logo-jumbo-verrast.svg"
            ></v-img>
        </router-link>
        <img
            src="../../assets/images/since-1921-white.svg"
            class="since-1921"
        >
    </v-app-bar>
</template>

<script>
export default {
    name: "Header",
    methods: {
        hasHistory () {
            return window.history.length > 2
        }
    }
}
</script>

<style scoped>

</style>
