<template>

    <div class="entry-list">
        <div class="entry-list-header" v-if="visibleEntries.length > 0">
            <slot name="header"></slot>
        </div>

        <EntryTile
            v-for="entry in visibleEntries"
            :key="entry.id"
            :entry="entry"
            :challenge="challenge"
            :subchallenge="subchallenge"
            ></EntryTile>
    </div>
</template>

<script>
import EntryTile from "./EntryTile";
export default {
    name: "EntryList",
    components: {EntryTile},
    props: {
        challenge: {
            required: true
        },
        subchallenge: {
            required: true
        },
        excludeEntryToken: {
            required: false
        }
    },
    mounted () {
        this.loadEntries()
    },
    data: () => ({
        entries: [],
    }),
    computed: {
        visibleEntries() {
            // TODO: paging enzo inbouwen
            return this.entries.filter(e => e.token !== this.excludeEntryToken)
        }
    },
    methods: {
        loadEntries() {
            if (this.subchallenge) {
                this.$store.dispatch('getSubchallengeEntries', {
                    challengeSlug: this.challenge.slug,
                    subchallengeSlug: this.subchallenge.slug
                })
                    .then(entries => {
                        this.entries = entries
                    })
            } else {
                this.$store.dispatch('getChallengeEntries', {
                    challengeSlug: this.challenge.slug,
                })
                    .then(entries => {
                        this.entries = entries
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
