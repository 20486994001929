<template>
    <v-container>
        <!--<h1 class="text-h1 mb-3"><strong>De spelregels</strong></h1>
        <v-img
            src="https://picsum.photos/200/200"
            height="100"
            cover
            class="rounded-lg mb-5"
        >
        </v-img>
        <v-alert
            color="red"
            dark
        >
            Niet aangeleverd
        </v-alert>
        <p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac diam blandit, tempus erat quis,
            pretium arcu. Morbi cursus viverra massa, sed ultricies justo egestas ut.</strong></p>
        <v-divider class="mb-4 mt-1"></v-divider>
        <ol class="pl-3 mb-10">
            <li class="mb-2">Hier komt een lijstopsomming te staan</li>
            <li class="mb-2">Hier komt een lijstopsomming te staan</li>
            <li>Hier komt een lijstopsomming te staan</li>
        </ol>
        <h2 class="text-h2 mb-3"><strong>Heb je een vraag</strong> over Jumbo Verrast?</h2>

        <v-expansion-panels accordion class="faq-list">
            <v-expansion-panel
                v-for="(item,i) in 3"
                :key="i"
            >
                <v-expansion-panel-header>In facilisis erat vel rutrum congue nam eleifend sapien ac ligula lacinia commodo?</v-expansion-panel-header>
                <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>-->
        <v-skeleton-loader v-if="!config" type="text"></v-skeleton-loader>
        <div v-if="config" v-html="config['rules.text']" class="mb-7"></div>

        <BottomContent></BottomContent>
    </v-container>
</template>

<script>
import BottomContent from "../../components/BottomContent";
import {mapState} from "vuex";

export default {
    name: "Rules",
    components: {BottomContent},
    computed:{
        ...mapState({
            config: state => state.config.config
        })
    }
}
</script>

<style scoped>

</style>
