import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import app from "./modules/app";
import auth from "./modules/auth";
import challenges from "./modules/challenges";
import config from "./modules/config";
import coaches from "./modules/coaches";
import news from "./modules/news";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
let plugins = []
if (debug) {
    plugins.push(createLogger)
}
plugins.push(createPersistedState({
    key: process.env.VUE_APP_VUEX_STORAGE_NAME,
    paths: [
        'auth.isLoggedIn',
        'auth.password',
        'challenges.likes',
    ]
}))


export default new Vuex.Store({
    strict: debug,
    plugins: plugins,
    modules: {
        app,
        auth,
        challenges,
        config,
        coaches,
        news
    }
})
