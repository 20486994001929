<template>
    <v-list flat class="footer-links pt-5">
        <p class="text-h1 mb-3"><strong>Handige informatie</strong></p>
        <v-list-item-group
            color="primary"
        >
            <v-divider></v-divider>
            <v-list-item link class="px-0" :to="{name:'Information'}">
                <v-list-item-content>
                    <v-list-item-title>Zo doe je mee <span class="arrow">></span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link class="px-0" :to="{name:'Rules'}">
                <v-list-item-content>
                    <v-list-item-title>De spelregels <span class="arrow">></span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link class="px-0" :to="{name:'Prizes'}">
                <v-list-item-content>
                    <v-list-item-title>Prijzen en verrassingen <span class="arrow">></span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link class="px-0" :to="{name:'HomeScreen'}">
                <v-list-item-content>
                    <v-list-item-title>Jumbo Verrast op je startscherm <span class="arrow">></span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link class="px-0" :to="{name:'Contact'}">
                <v-list-item-content>
                    <v-list-item-title>Contact <span class="arrow">></span></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "FooterLinks"
}
</script>

<style scoped>

</style>
