<template>
    <v-container id="news-summary" v-if="isVisible" class="pa-0"> <!-- ID is nodig om er naar toe te kunnen scrollen vanuit de NewsButton  -->
        <h2 class="text-h1 mb-4"><slot><strong>Nieuws</strong> & tips</slot></h2>

        <div v-for="newsPost in visibleNewsPosts" :key="newsPost.slug" class="news-post mb-3">
            <router-link :to="getLink(newsPost)">
                <div class="graphic rounded-lg mr-3" :class="{ 'has-video': newsPost.videoId && newsPost.image }">
                    <v-img
                        v-if="newsPost.videoId && newsPost.image"
                        :src="newsPost.image.url"
                        width="130"
                        height="75"
                        cover
                        class="rounded-lg"
                    >
                    </v-img>
                    <v-img
                        v-else-if="newsPost.image"
                        :src="newsPost.image.url"
                        width="130"
                        height="75"
                        cover
                        class="rounded-lg"
                    >
                    </v-img>
                </div>
                <div class="content">
                    <h2>{{newsPost.title}}</h2>
                    <p class="subtitle">{{getChallengeName(newsPost)}}</p>
                </div>
            </router-link>
        </div>
        <p class="text-center mb-0" v-if="hasMoreNews">
            <v-btn
                class="black--text px-5"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                href="#"
            >
                Meer nieuws
            </v-btn>
        </p>
        <v-divider class="mb-4 mt-5"></v-divider>
    </v-container>
</template>

<script>
export default {
    name: "NewsSummary",
    props: {
        challenge: {
            required: false,
            type: Object
        },
        subchallenge: {
            required: false,
            type: Object
        },
        maxItems: {
            required: false,
            default: 4
        },
        offset: {
            required: false,
            default: 0
        },
        compact: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted() {
        this.$store.dispatch('getNewsPosts');
        this.$store.dispatch('getChallenges');
    },
    computed: {
        newsPosts () {
            if (this.subchallenge) {
                return this.$store.state.news.news.filter(n => n.subChallenges.includes(this.subchallenge.slug));
            } else if (this.challenge) {
                return this.$store.state.news.news.filter(n => n.challenges.includes(this.challenge.slug));
            } else {
                return this.$store.state.news.news.filter(n => n.isVisibleOnHomepage);
            }
        },
        visibleNewsPosts () {
            return this.newsPosts.slice(this.offset, this.offset+this.maxItems);
        },
        isVisible () {
            return this.newsPosts.length > this.offset;
        },
        hasMoreNews () {
            return false; // nog niet geïmplementeerd
            //return this.newsPosts.length > this.maxItems;
        }
    },
    methods: {
        getChallengeName(newsPost) {
            if (newsPost.challenges.length > 0 && this.$store.state.challenges.challenges.length > 0) {
                let challengeSlug = newsPost.challenges[0];
                let challenge = this.$store.state.challenges.challenges.find(c => c.slug === challengeSlug);
                return challenge.name;
            }
            return 'Jumbo Verrast';
        },
        hasLink(newsPost) {
            return newsPost.challenges.length > 0;
        },
        getLink(newsPost) {
            let challengeSlug = newsPost.challenges[0];
            return {name: 'Challenge', params: { slug: challengeSlug }, hash: '#nieuws-' + newsPost.slug };
        }
    }
}
</script>

<style scoped>

</style>
