import apiClient from "@/api/apiClient";

// initial state
const state = {
    news: []
}

// getters
const getters = {
}

// actions
const actions = {
    getNewsPosts({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.news.length >= 1) {
            return state.news;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/news')
            .then(response => {
                commit('SET_NEWS', response.data);
                return response.data;
            })
    },
    getNewsPostDetail(_, {slug}) {
        return apiClient.get('/news/'+slug)
            .then(response => {
                return response.data
            })
    },
}

// mutations
const mutations = {
    SET_NEWS(state, news) {
        state.news = news;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
