<template>
    <div>
        <h2 class="text-h1 mb-5">Dit zijn je <strong>coaches!</strong></h2>

        <CoachTiles :max-coaches="4"></CoachTiles>

        <p class="text-center mb-0 pt-7">
            <v-btn
                class="black--text px-5"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                :to="{name:'Subchallenge', params: { challengeSlug: 'nieuweblikken', subchallengeSlug: 'stap-2' }}"
            >
                Bekijk alle coaches
            </v-btn>
        </p>
    </div>
</template>

<script>
import CoachTiles from "./CoachTiles";
export default {
    name: "ChallengeCoaches",
    components: {CoachTiles},
    props: {
        challenge: {
            type: Object
        }
    }
}
</script>

<style scoped>

</style>
