<template>
    <v-container id="news" v-if="isVisible" class="pa-0">
        <!-- ID is nodig om er naar toe te kunnen scrollen vanuit de NewsButton  -->
        <h2 class="text-h1 mb-4"><strong>Nieuws</strong> & tips</h2>

        <div v-for="(newsPost, index) in visibleNewsPosts" :key="newsPost.slug" class="news-post mb-3"
             :ref="'nieuws-'+newsPost.slug"
             :id="'nieuws-'+newsPost.slug">
            <h2 v-if="!newsPost.videoId">{{ newsPost.title }}</h2>
            <!-- TODO: video subtitle -->
            <VimeoVideo
                :video-id="newsPost.videoId"
                class="mx-n3 mx-md-0 mb-5 rounded-video rounded-lg"
                v-if="newsPost.videoId"
                :title="newsPost.title"
                :meta-class="'px-3 px-md-0'"
            ></VimeoVideo>
            <v-img
                v-if="!newsPost.videoId && newsPost.image"
                :src="newsPost.image.url"
                height="140"
                cover
                class="rounded-lg mb-3"
            >
            </v-img>

            <div v-if="newsPost.message" v-html="newsPost.message"></div>

            <v-divider class="mb-5 mt-3" v-if="index != visibleNewsPosts.length - 1"></v-divider>
        </div>
        <p class="text-center mb-0" v-if="hasMoreNews">
            <v-btn
                class="black--text px-5"
                color="grey lighten-1"
                depressed
                rounded
                outlined
                href="#"
            >
                Meer nieuws
            </v-btn>
        </p>
    </v-container>
</template>

<script>
import VimeoVideo from "../components/VimeoVideo";

export default {
    name: "News",
    components: {
        VimeoVideo
    },
    props: {
        challenge: {
            required: false,
            type: Object
        },
        subchallenge: {
            required: false,
            type: Object
        },
        maxItems: {
            required: false,
            default: 4
        }
    },
    mounted() {
        this.$store.dispatch('getNewsPosts')
            .then(() => {
                if (this.$route.hash) {
                    this.$nextTick(() => {
                        let ref = this.$refs[this.$route.hash.substring(1)];
                        if (ref.length >= 1) {
                            ref = ref[0];
                        }
                        if (ref) {
                            let y = ref.getBoundingClientRect().top + window.pageYOffset - 80;
                            window.scrollTo({ top: y, behaviour: 'smooth'});
                            window.setTimeout(() => {
                                let y = ref.getBoundingClientRect().top + window.pageYOffset - 80;
                                window.scrollTo({ top: y, behaviour: 'smooth'});
                            }, 500)
                        }
                    })
                }
            });
    },
    computed: {
        newsPosts() {
            if (this.subchallenge) {
                return this.$store.state.news.news.filter(n => n.subChallenges.includes(this.subchallenge.slug));
            } else if (this.challenge) {
                return this.$store.state.news.news.filter(n => n.challenges.includes(this.challenge.slug));
            } else {
                return this.$store.state.news.news.filter(n => n.isVisibleOnHomepage);
            }
        },
        visibleNewsPosts() {
            return this.newsPosts.slice(0, this.maxItems);
        },
        isVisible() {
            return this.newsPosts.length > 0;
        },
        hasMoreNews() {
            return false; // nog niet geïmplementeerd
            //return this.newsPosts.length > this.maxItems;
        }
    }
}
</script>

<style scoped>

</style>
